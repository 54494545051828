import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import Home_page from '@/components/HomePage.vue'
import Show from '@/components/AllShow/Show.vue'
import FirstShow from '@/components/AllShow/FirstShow.vue'
import SecondShow from '@/components/AllShow/SecondShow.vue'
import ThirdShow from '@/components/AllShow/ThirdShow.vue'
import FourthShow from '@/components/AllShow/FourthShow.vue'
import Competition from '@/components/Activity/Competition.vue'
import Daily from '@/components/Activity/Daily.vue'
import Gphoto from '@/components/Activity/Gphoto.vue'
import ContactHome from "@/components/Contact/ContactHome.vue"
import Map from '@/components/Contact/Map.vue'
import Way from '@/components/Contact/Way.vue'
import FifthShow from "@/components/AllShow/FifthShow";
import ActivityHome from "@/components/Activity/ActivityHome"


import Login from '@/components/Manage/Login'
import Message from "@/components/Manage/Message"
import Change from "@/components/Manage/Change"
import Main from "@/components/Manage/Main"
import Project from "@/components/Manage/Project/Project.vue"
import Competitions from "@/components/Manage/Competitions/Competitions.vue"
import Awards from "@/components/Manage/Awards/Awards.vue"
import Role from "@/components/Manage/Role/Role";
import Permission from "@/components/Manage/Permission/Permission";
import Dictionary from "@/components/Manage/Dictionary/Dictionary";
import Information from "@/components/MemberInfo/Information.vue";
import Activity from "@/components/Manage/Activity/Activity";
import Research from "@/components/Manage/Research/Research";
import Device from "@/components/Manage/Device/Information/Device";
import Record from "@/components/Manage/Device/Record/Record";

Vue.use(VueRouter)

const routes = [
    {path:'/',redirect:'/home'},//路由重定向
    {
        path: '*',
        name: 'ERROR404',
        component: () => import("../error/404"),
    },
    { path:'/home',
        component:Home,
        redirect:'/homepage',
        children:[{ path:'/homepage',component:Home_page },
            { path:'/show',
                component:Show,
                redirect:'/first',
                children:[
                    { path:'/first', component:FirstShow,},
                    { path:'/second',component:SecondShow},
                    { path:'/third',component:ThirdShow },
                    { path:'/fourth',component:FourthShow },
                    { path:'/fifth',component:FifthShow },
                ]
            },
            { path:'/activityHome',
                component:ActivityHome,
                redirect:'/competition',
                children:[
                    { path:'/competition',component:Competition },
                    { path:'/daily',component:Daily },
                    { path:'/gphoto',component:Gphoto },
                ]
            },
            { path:'/contact',
                component:ContactHome,
                redirect:'/way',
                children:[
                    { path:'/way',component:Way },
                    { path:'/map',component:Map },
                ]
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/information/:userName',
        name: 'information',
        component: Information
    },
    {
        path: '/main',
        name: 'Main',
        component: Main,
        meta: {
            requiresAuth: true,
            roleCode: 'main'
        },
        children: [
            {
                path: '/message',
                name: 'message',
                component: Message,
                meta: {
                    requiresAuth: true,
                    roleCode: 'message'
                }
            },
            {
                path: '/change',
                name: 'change',
                component: Change,
                meta: {
                    requiresAuth: true,
                    roleCode: 'change'
                }
            },
            {
                path: '/project',
                name: 'project',
                component: Project,
                meta: {
                    requiresAuth: true,
                    roleCode: 'project'
                }
            },
            {
                path: '/competitions',
                name: 'competitions',
                component: Competitions,
                meta: {
                    requiresAuth: true,
                    roleCode: 'competitions'
                }
            },
            {
                path: '/awards',
                name: 'awards',
                component: Awards,
                meta: {
                    requiresAuth: true,
                    roleCode: 'awards'
                }
            },
            {
                path: '/role',
                name: 'role',
                component: Role,
                meta: {
                    requiresAuth: true,
                    roleCode: 'role'
                }
            },
            {
                path: '/permission',
                name: 'permission',
                component: Permission,
                meta: {
                    requiresAuth: true,
                    roleCode: 'permission'
                }
            },
            {
                path: '/dictionary',
                name: 'dictionary',
                component: Dictionary,
                meta: {
                    requiresAuth: true,
                    roleCode: 'dictionary'
                }
            },
            {
                path: '/activity',
                name: '/activity',
                component: Activity,
                meta: {
                    requiresAuth: true,
                    roleCode: 'activity'
                }
            },
            {
                path: '/research',
                name: '/research',
                component: Research,
                meta: {
                    requiresAuth: true,
                    roleCode: 'research'
                }
            },
            {
                path: '/device',
                name: '/device',
                component: Device,
                meta: {
                    requiresAuth: true,
                    roleCode: 'device'
                }
            },
            {
                path: '/record',
                name: '/record',
                component: Record,
                meta: {
                    requiresAuth: true,
                    roleCode: 'record'
                }
            }
        ]
    }
]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode:"hash",
    routes,
})

// 筛选有权限的菜单
 export function filterRoute(arr, arr1) {
     const realRoutes = []
     arr.forEach((v) => {
         if (v.meta.requiresAuth) {
             realRoutes.push(v)
         }
     })
     return realRoutes
 }

 router.beforeEach((to, from, next) => {
     if (to.path === '/login') {
         next()
     } else {
         if (to.meta.requiresAuth) {//判断meta元信息里requiresAuth字段是否为true,为true即为需要进行登录验证的页面。
             const satoken = localStorage.getItem('satoken')
             if (!satoken) {
                 next('/login')
             } else {
                 // 筛选权限
                 next()
             }
         } else {
             next()
         }
     }
 })

export default router
