<template>
  <div>
    <el-dialog width="50%" :title="diaTitle" :visible.sync="dialogVisb" @close="handleClose" :close-on-click-modal="false">
      <el-form :model="data" label-width="80px" ref="deviceData" :rules="rules">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="设备名称" prop="name" class="name_item">
              <el-input :disabled="disableOrNot" v-model="data.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="设备类型" prop="type">
              <el-select :disabled="disableOrNot" v-model="data.type" style="width: 100%">
                <el-option
                    v-for="item in types"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="使用情况" prop="useCondition">
              <el-select :disabled="disableOrNot" v-model="data.useCondition" style="width: 100%">
                <el-option
                    v-for="item in conditions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="设备数量" prop="number">
              <el-input :disabled="disableNumber" v-model="data.number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="修改人" v-if="disableOrNot">
              <el-input :disabled="disableOrNot" v-model="data.updatedBy"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="修改时间" v-if="disableOrNot">
              <el-input :disabled="disableOrNot" v-model="data.updationDate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div v-if="isBuy">
        <div class="buy_header">
          <div style="text-align: left">
            <h1 class="buy_h1">购买信息</h1>
          </div>
          <div style="text-align: right">
            <el-button v-if="!disableOrNot" @click="add">新增</el-button>
          </div>
        </div>
        <el-form :ref="'buyDataRef' + index"  class="form_information" v-for="(buyData, index) in buyData" :key="index" :model="cloneBuyData(index)" label-width="80px">
          <i  v-if="!disableOrNot" class="el-icon-close delete-icon" @click="reduce(index)"></i>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="购买日期" prop="buyDate"
                            class="name_item"
                            :rules="[{ required: true,
                            message: '购买日期不能为空',
                            trigger: 'blur'}]">
                <div class="block">
                  <el-date-picker
                      style="width: 100%"
                      :disabled="disableOrNot"
                      v-model="buyData.buyDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="请选择购买日期">
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="购买人" prop="existStudentIds">
                <student-tree
                    style="width: 100%"
                    :disableOrNot="disableOrNot"
                    :existStudentIds="buyData.existStudentIds"
                    :studentData="studentDataAll"
                    :studentDataProps="{id: 'id',label: 'label',children: 'children'}"
                    @update:userIds="handleUpdateStudent({ index: index, event: $event })"
                    placeholder="请选择学生姓名"
                    class="position-select"
                    size="mini"
                    filterable>
                </student-tree>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
                  <el-form-item label="发票文件" v-if="buyData.invoiceIsNone">
                    <el-upload v-if="type === 'edit' || type === 'add'"
                               :on-preview="handlePictureCardPreview"
                               :on-remove="file => handleRemoveFile(file, index)"
                               :before-upload="file => beforeUploadFile(file, index)"
                               :file-list="buyData.invoiceList"
                               action
                               multiple>
                      <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                    <el-link
                        v-if="type === 'detail'"
                        class="mr30"
                        style="display: block"
                        :href="baseUrl + buyData.invoiceUrl"
                        type="primary"
                        target="_blank"
                    >{{ buyData.invoiceUrl.match(/[^\/]+$/)[0] }}
                    </el-link>
                  </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="设备图片" v-if="buyData.imageIsNone">
                <el-upload v-if="type === 'edit' || type === 'add'"
                           :on-preview="upload"
                           :on-remove="file => handleRemoveImage(file, index)"
                           :before-upload="file => beforeUploadImage(file, index)"
                           :file-list="buyData.imageList"
                           action
                           multiple>
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
                <el-link
                    v-if="type === 'detail'"
                    class="mr30"
                    style="display: block"
                    :href="baseUrl + buyData.imageUrl"
                    type="primary"
                    target="_blank"
                >{{ buyData.imageUrl.match(/[^\/]+$/)[0] }}
                </el-link>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="设备数量" prop="number"
                            :rules="[{ required: true,
                            message: '购买数量不能为空',
                            trigger: 'blur'}]">
                <el-input :disabled="disableOrNot" v-model="buyData.number"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="修改人" v-if="disableOrNot">
                <el-input :disabled="disableOrNot" v-model="buyData.updatedBy"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="修改时间" v-if="disableOrNot">
                <el-input :disabled="disableOrNot" v-model="buyData.updationDate"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div style="text-align: right; margin-top: 10px">
        <el-button @click="handleClose()">取消</el-button>
        <el-button v-if="!disableOrNot" type="primary" @click="save()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { BASE_URL } from '@/utils/constant';
import dictionary from "@/api/dictionary";
import DeviceService from "../../../../../api/device"
import manageService from "@/api/manage";
import StudentTree from "@/components/tree/StudentTree";
import {nanoid} from "nanoid";

export default {
  name: 'AddOrModifyDialog',
  components: {StudentTree},
  props: {
    type: String,
    deviceId: String,
    dialogFormVisible: Boolean,
  },
  data() {
    return {
      disableOrNot: false,
      diaTitle: '',
      data: {},
      buyData: [
          {
            //父组件给子组件传递的值一定要初始化，不然响应会失败
            existStudentIds: [],
            imageList: [],
            invoiceList: [],
            invoiceUrl: '',
            imageUrl: '',
          },{
            existStudentIds: [],
            imageList: [],
            invoiceList: [],
            invoiceUrl: '',
            imageUrl: '',
          },{
            existStudentIds: [],
            imageList: [],
            invoiceList: [],
          invoiceUrl: '',
          imageUrl: '',
          },{
            existStudentIds: [],
            imageList: [],
            invoiceList: [],
          invoiceUrl: '',
          imageUrl: '',
          },{
            existStudentIds: [],
            imageList: [],
            invoiceList: [],
          invoiceUrl: '',
          imageUrl: '',
          },{
            existStudentIds: [],
            imageList: [],
            invoiceList: [],
          invoiceUrl: '',
          imageUrl: '',
          }
      ],
      rules: {
        name: [{
          required: true,
          message: '设备名称不能为空',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '设备类型不能为空',
          trigger: 'blur'
        }],
        useCondition: [{
          required: true,
          message: '使用情况不能为空',
          trigger: 'blur'
        }]
      },
      baseUrl : BASE_URL,
      activities: [],
      conditions: [],
      types: [],
      studentDataAll: [],
      isBuy: false,
      disableNumber: false,
    }
  },
  watch: {
    type: {
      handler(newval, oldval) {
        if (newval === 'add') {
          this.diaTitle = '新增'
          this.disableOrNot = false
          this.disableNumber = false
        }
        if (newval === 'edit') {
          this.diaTitle = '编辑'
          this.disableOrNot = false
          this.isBuy = true
          this.disableNumber = true
        }
        if (newval === 'detail') {
          this.diaTitle = '详情'
          this.disableOrNot = true
          this.isBuy = true
          this.disableNumber = true
        }
      },
      immediate: true,
    },
  },
  computed: {
    dialogVisb: {
      get() {
        return this.dialogFormVisible
      },
      set(v) {
        this.$emit('update:dialogFormVisible', v)
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    cloneBuyData(index) {
      return this.buyData[index];
    },
    init(){
      this.$nextTick(() => {
        this.searchAllUsers().then(res => {
          this.getDic();
          if (this.deviceId && this.deviceId !== '') {
            DeviceService.getByDeviceId(this.deviceId).then((res) => {
              if (res.state) {
                this.data = res.data;
                DeviceService.getBuyByDeviceId(this.deviceId).then((res) => {
                  this.buyData = res.data;
                  this.buyData.forEach(b => {
                    b.imageIsNone = true;
                    b.invoiceIsNone = true;
                    b.existStudentIds = [b.buyBy];
                    if (b.imageUrl.length !== 0) {
                      const item = {
                        imageUrl: b.imageUrl,
                        name: b.imageUrl.match(/[^\/]+$/)[0]
                      };
                      let a = [];
                      a.push(item)
                      b.imageList = a;
                    }
                    if (b.invoiceUrl.length !== 0) {
                      const item = {
                        fileUrl: b.invoiceUrl,
                        name: b.invoiceUrl.match(/[^\/]+$/)[0]
                      };
                      let a = [];
                      a.push(item)
                      b.invoiceList = a;
                    }
                    if (this.type === "detail") {
                      if (b.imageUrl.length === 0) {
                        b.imageIsNone = false;
                      }
                      if (b.invoiceUrl.length === 0) {
                        b.invoiceIsNone = false;
                      }
                    }
                  })
                })
              }
            })
          }
        })
      })
    },
    save() {
      this.$refs['deviceData'].validate((valid) => {
        if (valid) {
          if (this.type === 'add') {
            DeviceService.insertOrUpdateDevice(this.data).then((res) => {
              if (res.state) {
                this.$message.success('新增成功')
                this.handleClose()
                this.$emit('refresh')
              }
            })
          }
          if (this.type === 'edit') {
            this.data.number = 0;
            this.buyData.forEach(obj => {
              this.data.number += obj.number;
            });
            DeviceService.insertOrUpdateDevice(this.data).then((res) => {
              if (res.state) {
                this.saveBuy();
              }
            })
          }
        } else {
          return false;
        }
      })
    },
    saveBuy() {
      let flag = 0;
      for (let index = 0; index < this.buyData.length; index++) {
        this.$refs['buyDataRef' + index].forEach((formRef) => {
          formRef.validate((valid) => {
            if (valid) {
              flag ++;
            } else {
              return false;
            }
          })
        })
      }
      if (flag === this.buyData.length) {
        this.buyData.forEach(obj => {
          obj.deviceId = this.deviceId;
        });
        if (this.type === 'edit') {
          DeviceService.insertOrUpdateDeviceBuy(this.buyData).then((res) => {
            if (res.state) {
              this.$message.success('保存成功')
              this.handleClose()
              this.$emit('refresh')
            }
          })
        }
      }
    },
    searchAllUsers() {
      return new Promise((resolve, reject) => {
        manageService.searchAllUsers().then((res) => {
          this.studentDataAll = this.studentListToTree(res.data);
          resolve();
        })
      })
    },
    handleUpdateStudent({ index, event }) {
      this.buyData[index].buyBy = event.join(", ");
    },
    add() {
      let arr = {
        deviceId: '',
        buyDate: '',
        buyBy: '',
        existStudentIds: [],
        studentId: '',
        invoiceUrl: '',
        imageUrl: '',
        imageList: [],
        invoiceList: [],
        imageIsNone: true,
        invoiceIsNone: true,
        number: '',
        updatedBy: '',
        updationDate: '',
      }
      this.buyData.push(arr)
    },
    // 表单减少一行
    reduce(index) {
      this.buyData.splice(index, 1);
    },
    // 字典初始化
    getDic(){
      // 使用情况名称
      dictionary.getDicByCode("use_condition").then(res => {
        if (res.state){
          this.conditions = res.data;
        }
      })
      dictionary.getDicByCode("device_type").then(res => {
        if (res.state){
          this.types = res.data;
        }
      })
    },
    upload(file) {
      window.open(this.baseUrl + file.imageUrl, '_blank')
    },
    handlePictureCardPreview(file) {
      window.open(this.baseUrl + file.invoiceUrl, '_blank')
    },
    handleRemoveImage(file, index) {
      console.log(index);
      this.buyData[index].imageUrl = "";
      this.buyData[index].imageList = [];
      console.log(this.buyData);
    },
    handleRemoveFile(file, index) {
      this.buyData[index].invoiceUrl = "";
      this.buyData[index].invoiceList = [];
      console.log(this.buyData);
    },
    //上传图片
    beforeUploadImage(file, index) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return false;
      }

      let form = new FormData()
      form.append("file", file);
      DeviceService.uploadImage(form).then(res => {
        const item = {
          imageUrl: res.data,
          name: res.data.match(/[^\/]+$/)[0],
          uid: nanoid()
        };
        let a = [];
        a.push(item)
        this.buyData[index].imageList = a
        this.buyData[index].imageUrl = res.data;
      });
      return false;
    },

    beforeUploadFile(file,index) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!')
        return false;
      }

      let form = new FormData()
      form.append("file", file);
      DeviceService.uploadFile(form).then(res => {
        const item = {
          imageUrl: res.data,
          name: res.data.match(/[^\/]+$/)[0],
          uid: nanoid()
        };
        let a = [];
        a.push(item)
        this.buyData[index].invoiceList = a
        // this.$set(this.buyData[index], 'invoiceList', a);
        this.buyData[index].invoiceUrl = res.data
      });
      return false;
    },
    handleClose() {
      this.data = {}
      this.dialogVisb = false
    },
    // 学生树形结构数据
    studentListToTree(data) {

      let studentData = [];
      let professionMap = {}; // 用于快速查找专业节点
      let entryYearMap = {}; // 用于快速查找入学年份节点

      for (const student of data) {
        if (!student.profession || !student.entryYear || !student.id || !student.userName || student.roleId !== '1003') {
          continue; // 跳过缺失必要属性的学生对象
        }

        // 处理专业节点
        if (!professionMap[student.profession]) {
          const professionNode = this.createNode(this.createRandomId(), student.profession);
          studentData.push(professionNode);
          professionMap[student.profession] = professionNode;
          entryYearMap[student.profession] = {}; // 初始化当前专业的入学年份映射
        }

        // 处理入学年份节点
        const professionNode = professionMap[student.profession];
        if (!entryYearMap[student.profession][student.entryYear]) {
          const entryYearNode = this.createNode(this.createRandomId(), student.entryYear.toString());
          professionNode.children.push(entryYearNode);
          entryYearMap[student.profession][student.entryYear.toString()] = entryYearNode;
        }

        // 添加学生信息节点
        const entryYearNode = entryYearMap[student.profession][student.entryYear];
        entryYearNode.children.push(this.createNode(student.id, student.userName, true));
      }

      return studentData;
    },
    // 创建树节点
    createNode(id, label, isLeaf = false) {
      return {
        id,
        label,
        children: [],
        ...(isLeaf && {isLeaf: true}), // 只有叶子节点才添加isLeaf属性
      };
    },
    // 生成一个随机的字符串作为 ID
    createRandomId() {
      return Math.random().toString(36).substr(2, 9);
    }
  },
}
</script>
<style scoped>
.buy_header {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buy_h1 {
  line-height: 18px;
  font-size: 18px;
  font-weight: bolder;
  color: #303133;
}

.form_information {
  margin-top: 20px;
  border: 1px solid #ccc; /* 设置边框为灰色 */
  padding: 5px; /* 可选：添加内边距以增加内容与边框之间的空隙 */
  border-radius: 5px; /* 可选：添加圆角以使边框看起来更加柔和 */
}

.name_item {
  margin-top: 15px;
}

.delete-icon {
  margin-left: 97%;
  cursor: pointer;
  color: #999;
}

</style>
