import request from '@/utils/request.js';

export default {

    searchListDevice(data) {
        return request({
            url:"/device/pageDevice",
            method:"post",
            data
        })
    },
    deleteDevice(ids) {
        return request({
            url:`/device/removeDevice?ids=${ids}`,
            method:"post"
        })
    },
    insertOrUpdateDevice(data) {
        return request({
            url:"/device/insertOrUpdateDevice",
            method:"post",
            data
        })
    },
    getByDeviceId(id){
        return request({
            url:`/device/getByDeviceId/${id}`,
            method:"get"
        })
    },
    deleteDeviceBuy(ids) {
        return request({
            url:`/device/removeDeviceBuy?ids=${ids}`,
            method:"post"
        })
    },
    insertOrUpdateDeviceBuy(data) {
        return request({
            url:"/device/insertOrUpdateDeviceBuy",
            method:"post",
            data
        })
    },
    getBuyByDeviceId(id){
        return request({
            url:`/device/getBuyByDeviceId/${id}`,
            method:"get"
        })
    },
    searchListDeviceRecord(data) {
        return request({
            url:"/device/pageDeviceRecord",
            method:"post",
            data
        })
    },
    deleteDeviceRecord(ids) {
        return request({
            url:`/device/removeDeviceRecord?ids=${ids}`,
            method:"post"
        })
    },
    insertOrUpdateDeviceRecord(data) {
        return request({
            url:"/device/insertOrUpdateDeviceRecord",
            method:"post",
            data
        })
    },
    getByDeviceRecordId(id){
        return request({
            url:`/device/getByDeviceRecordId/${id}`,
            method:"get"
        })
    },
    uploadImage(data) {
        return request({
            type: "file",
            url: "/device/uploadImage",
            method: "post",
            data
        })
    },
    uploadFile(data) {
        return request({
            type: "file",
            url: "/device/uploadFile",
            method: "post",
            data
        })
    },
    export(data) {
        return request({
            url:"/device/export",
            method:"post",
            responseType:'blob',
            data
        })
    },
    exportRecord(data) {
        return request({
            url:"/device/exportRecord",
            method:"post",
            responseType:'blob',
            data
        })
    },
}