<template>
  <div class="wareHouseBody">
    <div class="flex-column height100">
      <div class="searchBox">
        <el-form label-width="72px">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="设备类型">
                <el-select v-model="searchParams.params.type" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in types"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="设备名称">
                <el-input v-model="searchParams.params.name" placeholder="请输入设备名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="使用情况">
                <el-select v-model="searchParams.params.useCondition" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in conditions"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24" type="flex" align="middle" style="margin: 0">
            <el-row :span="15">
              <el-button type="primary" @click="handleAdd()" >新增</el-button>
              <el-button type="primary" @click="exportFile()" :loading="exportLoading">
                导出
              </el-button>
              <el-button type="primary" @click="del()">批量删除</el-button>
              <el-button type="primary" @click="load()">查询</el-button>
              <el-button type="primary" @click="reset()">重置</el-button>
            </el-row>
          </el-row>
        </el-form>
      </div>

      <div class="tableData" style="height: 100%">
        <el-table
            ref="filterTable"
            :data="activityList"
            :show-overflow-tooltip="true"
            @selection-change="handleSelectionChange"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="name" label="设备名称" min-width="150"/>
          <el-table-column prop="type" label="设备类型" min-width="150"/>
          <el-table-column prop="useCondition" label="使用情况" min-width="150"/>
          <el-table-column prop="userName" label="持有用户" min-width="150"/>
          <el-table-column prop="number" label="设备数量" min-width="150"/>
          <el-table-column label="操作" min-width="150" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="viewItem(scope.row)">详情</el-button>
              <el-button @click="handleEdit(scope.row)" size="mini" v-btn-permission="'user_edit'">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
            style="text-align: right;"
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page="this.searchParams.pageBean.pageNum"
            :page-size="this.searchParams.pageBean.pageSize"
            :page-sizes="pageSizes" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <AddOrModifyDialog v-if="dialogFormVisible" :dialogFormVisible.sync="dialogFormVisible" :deviceRecordId="deviceRecordId" :type="type" @refresh="load"/>
  </div>
</template>

<script>
import DeviceService from "../../../../api/device"
import AddOrModifyDialog from "@/components/Manage/Device/Record/components/AddOrModify";
import dictionary from "@/api/dictionary";
import {processDataFlow} from "@/utils";

export default {
  name: 'Device',
  components: {AddOrModifyDialog},
  data() {
    return {
      searchParams: {
        pageBean: {
          pageNum: 1,
          pageSize: 10
        },
        params: {
          name: '',
          type: '',
          useCondition: '',
          number: ''
        }
      },
      exportLoading: false,
      types: [],
      conditions: [],
      pageSizes: [10, 20, 30, 50, 100], //可选择的一页多少条
      total: 0, //总条目数
      activityList: [],
      multipleSelection: [],
      dialogFormVisible: false,
      deviceRecordId: '',
      type: '',
      YesOrNot: [
        {
          name: '是',
          value: "1"
        },
        {
          name: '否',
          value: "0"
        },
      ],
    }
  },
  mounted() {
    this.load();
    this.getDic();
  },
  methods: {
    load() {
      DeviceService.searchListDeviceRecord(this.searchParams).then(res => {
        this.activityList = res.rows;
        this.total = res.total;
      })
    },
    del() {
      let ids = this.multipleSelection.map((item) => item.id).join(",");
      let self = this;
      self
          .$confirm("此操作将删除已选择数据, 是否继续?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            DeviceService.deleteDeviceRecord(ids)
                .then((res) => {
                  if (res.state) {
                    self.$message.success(res.message);
                  } else {
                    self.$message.error(res.message);
                  }
                }).finally(() => {
              self.load();
            });
          });
    },
    reset() {
      this.searchParams = {
        pageBean: {
          pageNum: 1,
          pageSize: 10
        },
        params: {
          type: '',
          isShow: ''
        },
      }
      this.load()
    },
    // 字典初始化
    getDic(){
      // 使用情况
      dictionary.getDicByCode("use_condition").then(res => {
        if (res.state){
          this.conditions = res.data;
        }
      })
      // 设备类型
      dictionary.getDicByCode("device_type").then(res => {
        if (res.state){
          this.types = res.data;
        }
      })
    },
    handleAdd() {
      this.type = "add";
      this.deviceRecordId = undefined;
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      this.type = 'edit';
      this.deviceRecordId = row.id;
      this.dialogFormVisible = true
    },
    viewItem(row) {
      this.type = 'detail';
      this.deviceRecordId = row.id;
      this.dialogFormVisible = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    //page改变时的回调函数，参数为当前页码
    currentChange(val) {
      this.searchParams.pageBean.pageNum = val;
      this.load();
    },
    //size改变时回调的函数，参数为当前的size
    sizeChange(val) {
      this.searchParams.pageBean.pageSize = val;
      this.load();
    },
    exportFile() {
      DeviceService.exportRecord(this.searchParams)
          .then(res => {
            processDataFlow(res, '历史记录汇总表.xlsx')
          })
          .finally(() => {
            this.exportLoading = false
          })
    },
  }
}
</script>

<style scoped>
.flex-column {
  display: flex;
  flex-direction: column;
}

.searchBox {
  margin-top: 10px;
  padding: 20px;
}

.tableData {
//margin-top: 20px;
  border-radius: 20px;
  border: thin solid #eaebec;
  width: 98%;
  margin-left: 1%;
}
</style>