import { render, staticRenderFns } from "./AddOrModify.vue?vue&type=template&id=3fc7d5fb&scoped=true&"
import script from "./AddOrModify.vue?vue&type=script&lang=js&"
export * from "./AddOrModify.vue?vue&type=script&lang=js&"
import style0 from "./AddOrModify.vue?vue&type=style&index=0&id=3fc7d5fb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc7d5fb",
  null
  
)

export default component.exports