<template>
  <div>
    <div class="header">
      <span class="header-span">个人信息</span>
      <el-button @click="edit()" v-if="isShowEdit === '1'" class="header-edit">编辑</el-button>
    </div>
    <div class="formTable">
      <el-form :model="messageData" label-width="150px" ref="messageData">
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item label="照片">
              <template v-if="isShow">
                <img v-if="isImage" class="image_member" :src="baseUrl + messageData.imageUrl" @click="upload(messageData.imageUrl)">
              </template>
              <template v-else>
                <el-upload
                    :on-success="handleFileSuccess"
                    :on-remove="handleRemove"
                    :before-upload="beforeUpload"
                    :file-list="messageData.imageUrl"
                    list-type="picture-card"
                    action
                    >
                  <i v-if="!isImage" slot="default" class="el-icon-plus"></i>
                  <img v-if="isImage" class="image_member" :src="baseUrl + messageData.imageUrl">
                </el-upload>
                <el-dialog :visible.sync="dialogVisibleImage">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item prop="name" label="姓名" :rules="nameRules">
              <el-input v-model="messageData.name" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="academy" label="学院" :rules="academyRules">
              <el-select v-model="messageData.academy" :disabled="isShow" style="width: 100%"
                         placeholder="请选择所属学院">
                <el-option
                    v-for="item in academys"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'&& isManager" prop="manageYear" label="管理年份"
                          :rules="manageYearRules">
              <template v-if="isShow">
                <el-input v-model="messageData.manageYear" :disabled="isShow" readonly></el-input>
              </template>
              <template v-else>
                <el-date-picker
                    style="width: 100%"
                    size="medium"
                    v-model="messageData.manageYear"
                    type="year"
                    placeholder="请选择管理年份"
                    value-format="yyyy"
                ></el-date-picker>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item v-if=" isManager || isTeacher" prop="introduce" label="自我介绍" :rules="introduceRules">
              <el-input type="textarea" v-model="messageData.introduce" :disabled="isShow"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" prop="nation" label="民族" :rules="nationRules">
              <el-input v-model="messageData.nation" :disabled="isShow"/>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" label="籍贯" prop="nativePlace" :rules="nativePlaceRules">
              <template v-if="isShow">
                <el-input v-model="messageData.nativePlace" :disabled="isShow" readonly></el-input>
              </template>
              <template v-else>
                <el-cascader
                    style="width: 100%"
                    size="medium"
                    :options="options"
                    v-model="cascaderValue"
                    @change="handleChange">
                </el-cascader>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" prop="age" label="出生日期" :rules="ageRules">
              <template v-if="isShow">
                <el-input v-model="messageData.age" :disabled="isShow" readonly></el-input>
              </template>
              <template v-else>
                <el-date-picker
                    style="width: 100%"
                    size="medium"
                    v-model="messageData.age"
                    type="year"
                    placeholder="请选择出生日期"
                    value-format="yyyy"
                ></el-date-picker>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" prop="idNumber" label="身份证号" :rules="idNumberRules">
              <el-input
                  v-model="messageData.idNumber"
                  :disabled="isShow"
                  placeholder="请输入身份证号"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" label="入学年份" prop="entryYear" :rules="entryYearRules">
              <template v-if="isShow">
                <el-input v-model="messageData.entryYear" :disabled="isShow" readonly/>
              </template>
              <template v-else>
                <el-date-picker
                    style="width: 100%"
                    v-model="messageData.entryYear"
                    type="year"
                    placeholder="请选择入学年份"
                    size="medium"
                    value-format="yyyy">
                </el-date-picker>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" label="所属专业" prop="profession" :rules="professionRules">
              <el-select
                  v-model="messageData.profession"
                  :disabled="isShow"
                  style="width: 100%"
                  placeholder="请选择所属专业">
                <el-option
                    v-for="item in professions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" prop="studentId" label="学生学号" :rules="studentIdRules">
              <el-input
                  v-model="messageData.studentId"
                  :disabled="isShow"
                  placeholder="请输入学号"/>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" label="团队职务" prop="job" :rules="jobRules">
              <template v-if="isShow">
                <el-input v-model="messageData.job" :disabled="isShow" readonly/>
              </template>
              <template v-else>
                <el-select
                    v-model="messageData.job"
                    multiple
                    style="width: 100%"
                    placeholder="请选择团队职务">
                  <el-option
                      v-for="item in jobs"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" prop="phone" label="联系电话" :rules="phoneRules">
              <el-input v-model="messageData.phone" :disabled="isShow"/>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" prop="email" label="电子邮箱" :rules="emailRules">
              <el-input v-model="messageData.email" :disabled="isShow" required/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" prop="bankNumber" label="银行卡号" :rules="bankNumberRules">
              <el-input v-model="messageData.bankNumber" :disabled="isShow"/>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" label="工作公司">
              <el-input v-model="messageData.company" :disabled="isShow"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" label="考研分数">
              <el-input v-model="messageData.score" :disabled="isShow"/>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item v-if="isStudent === '1'" label="研究院校">
              <el-input v-model="messageData.institution" :disabled="isShow"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item v-if="isStudent === '1'" label="学科竞赛">
          <template v-if="isShow">
            <el-card style="width: 90%">
              <div v-html="messageData.competition"></div>
            </el-card>
          </template>
          <template v-else>
            <div style="border: 1px solid #ccc; width: 90%;" class="edit">
              <Toolbar
                  style="border-bottom: 1px solid #ccc;height:100px;"
                  :editor="this.editor1"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
              />
              <Editor
                  style="height: 100px; overflow-y: hidden;"
                  v-model="messageData.competition"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onCreated1"
              />
            </div>
          </template>
        </el-form-item>
        <el-form-item v-if="isStudent === '1'" label="校园经历">
          <template v-if="isShow">
            <el-card style="width: 90%">
              <div v-html="messageData.campusExperience"></div>
            </el-card>
          </template>
          <template v-else>
            <div style="border: 1px solid #ccc; width: 90%;" class="edit">
              <Toolbar
                  style="border-bottom: 1px solid #ccc;height:100px;"
                  :editor="this.editor2"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
              />
              <Editor
                  style="height: 100px; overflow-y: hidden;"
                  v-model="messageData.campusExperience"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onCreated2"
              />
            </div>
          </template>
        </el-form-item>
        <el-form-item v-if="isStudent === '1'" label="个人寄语">
          <template v-if="isShow">
            <el-card style="width: 90%">
              <div v-html="messageData.message"></div>
            </el-card>
          </template>
          <template v-else>
            <div style="border: 1px solid #ccc; width: 90%;" class="edit">
              <Toolbar
                  style="border-bottom: 1px solid #ccc;height:100px;"
                  :editor="this.editor3"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
              />
              <Editor
                  style="height: 100px; overflow-y: hidden;"
                  v-model="messageData.message"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onCreated3"
              />
            </div>
          </template>
        </el-form-item>
      </el-form>
      <div class="button2" type="flex" v-if="isShowEdit === '1'">
        <el-button id="cancel" @click="cancel()">取消</el-button>
        <el-button id="submit" @click="editSubmit()">确认</el-button>
      </div>
    </div>
  </div>

</template>
<script>
import {Editor, Toolbar} from '@wangeditor/editor-for-vue';
import MessageService from '../../api/message'
import {provinceAndCityData, codeToText} from 'element-china-area-data'
import { BASE_URL } from '@/utils/constant';
import dictionary from "@/api/dictionary";


export default {
  components: {Editor, Toolbar},
  name: "Message",
  props: {
    userDetailId: {
      type: String,
      default: ''
    },
    userName: {
      type: String,
      default: ''
    },
    isShowEdit: {
      type: String,
      default: '1'
    },
    isStudent: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      baseUrl: BASE_URL,
      selectedDate: new Date("2000"),
      cascaderValue: [],
      oldAge: 0,
      messageData: {},
      oldEntryYear: 0,
      oldJob: '',
      oldPlace: '',
      editor1: '',
      editor2: '',
      editor3: '',
      isShow: true,
      options: provinceAndCityData,
      toolbarConfig: {},
      editorConfig: {
        placeholder: '请输入内容...'
      },
      academys: [],
      jobs: [],
      belongs: [
        {
          name: '是',
          value: 1
        },
        {
          name: '否',
          value: 0
        }
      ],
      formData: {
        position_id: '2',
        position_name: []
      },
      isBelong: 0,
      testData: [],
      professions: [],
      mode: 'default', // or 'simple'
      dialogFormVisible_query: false,
      dialogFormVisible_edit: false,
      isManager: false,
      isTeacher: false,
      headersObj: {
        satoken:window.localStorage.getItem('satoken'),
        Authorization:window.localStorage.getItem('satoken')
      },
      isImage: false,
      dialogImageUrl: '',
      dialogVisibleImage: false
    }

  },
  computed: {
    studentIdRules() {
      return [
        {
          required: this.isBelong === 1,
          trigger: "blur",
          message: '学号不能为空',
          validator: this.validatorStudentId
        }
      ];
    },
    nationRules() {
      return [
        {
          required: this.isBelong === 1,
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (this.isBelong === 1) {
              if (/^-/.test(value)) {
                callback(new Error("输入不能为负数"))
              } else if (!/[\u4e00-\u9fa5]$/.test(value)) {
                callback(new Error('民族名称必须为汉字'))
              } else {
                callback()
              }
            } else {
              callback()
            }
          }
        }
      ];
    },
    nameRules() {
      return [{
        required: this.isBelong === 1,
        message: '姓名不能为空',
        trigger: "blur",
      }]
    },
    academyRules() {
      return [{
        required: this.isBelong === 1,
        message: '学院不能为空',
        trigger: "blur",
      }]
    },
    nativePlaceRules() {
      return [{
        required: this.isBelong === 1,
        message: '籍贯不能为空',
        trigger: "blur",
      }]
    },
    ageRules() {
      return [{
        required: this.isBelong === 1,
        message: '出生年份不能为空',
        trigger: "blur",
      }]
    },
    manageYearRules() {
      return [{
        required: this.isBelong === 1,
        message: '管理年份不能为空',
        trigger: "blur",
      }]
    },
    introduceRules() {
      return [{
        required: this.isBelong === 1,
        message: '自我介绍不能为空',
        trigger: "blur",
      }]
    },
    entryYearRules() {
      return [{
        required: this.isBelong === 1,
        message: '入学年份不能为空',
        trigger: "blur",
      }]
    },
    professionRules() {
      return [{
        required: this.isBelong === 1,
        message: '专业不能为空',
        trigger: "blur",
      }]
    },
    jobRules() {
      return [{
        required: this.isBelong === 1,
        message: '职务不能为空',
        trigger: "blur",
      }]
    },
    phoneRules() {
      return [{
        required: this.isBelong === 1,
        trigger: "blur",
        validator: (rule, value, callback) => {
          if (this.isBelong === 1) {
            if (/^-/.test(value)) {
              callback(new Error("输入不能为负数"))
            } else if (!/^\d+$/.test(value)) {
              callback(new Error("手机号只能包含数字"))
            } else {
              callback()
            }
          } else {
            callback()
          }
        }
      }]
    },
    emailRules() {
      return [{
        required: this.isBelong === 1,
        trigger: "blur",
        validator: (rule, value, callback) => {
          if (this.isBelong === 1) {
            if (/^-/.test(value)) {
              callback(new Error("输入不能为负数"))
            } else if (!/\S+@\S+\.\S+/.test(value)) {
              callback(new Error('请输入有效的邮箱地址'))
            } else {
              callback()
            }
          } else {
            callback()
          }
        }
      }]
    },
    idNumberRules() {
      return [{
        required: this.isBelong === 1,
        trigger: "blur",
        validator: (rule, value, callback) => {
          if (this.isBelong === 1) {
            if (/^-/.test(value)) {
              callback(new Error("输入不能为负数"))
            } else if (!/^\d{17}(\d|X|x)$/.test(value)) {
              callback(new Error('请输入有效的身份证号'))
            } else {
              callback()
            }
          } else {
            callback()
          }
        }
      }]
    },
    bankNumberRules() {
      return [{
        required: this.isBelong === 1,
        trigger: "blur",
        validator: (rule, value, callback) => {
          if (this.isBelong === 1) {
            if (/^-/.test(value)) {
              callback(new Error("输入不能为负数"))
            } else if (!/^\d{16,19}$/.test(value)) {
              callback(new Error('请输入有效的银行卡号'))
            } else {
              callback()
            }
          } else {
            callback()
          }
        }
      }]
    },
  },
  watch: {},
  mounted() {
    this.load();
    this.getDic();
  },
  methods: {
    edit() {
      if (this.messageData.job && this.messageData.job !== "") {
        this.messageData.job = this.messageData.job.split(',');
        this.oldJob = this.messageData.job.toString();
      }
      if (this.messageData.nativePlace && this.messageData.nativePlace !== "") {
        const [province, city] = this.messageData.nativePlace.split('/');
        const provinceCode = provinceAndCityData.find(item => item.label === province);
        const cityCode = provinceCode.children.find(item => item.label === city);
        this.cascaderValue = [provinceCode.value, cityCode.value];
        this.oldPlace = this.cascaderValue.toString();
      }
      if (this.messageData.age && this.messageData.age !== "") {
        this.messageData.age = this.messageData.age.toString();
        this.oldAge = this.messageData.age.toString();
      }
      if (this.messageData.entryYear && this.messageData.entryYear !== "") {
        this.messageData.entryYear = this.messageData.entryYear.toString();
        this.oldEntryYear = this.messageData.entryYear.toString();
      }
      if (this.messageData.manageYear && this.messageData.manageYear !== "") {
        this.messageData.manageYear = this.messageData.manageYear.toString();
      }
      this.isShow = false;
      const isSubmit = document.getElementById("submit")
      isSubmit.style.display = "block";
      const cancel = document.getElementById("cancel")
      cancel.style.display = "block";
    },
    editSubmit() {
      this.convert();
      // if (!/[A-Za-z]/.test(this.messageData.grade)) {
      //   this.messageData.academy = this.academys.find(item => item.value === this.messageData.academy);
      // }
      // if (!/[A-Za-z]/.test(this.messageData.profession)) {
      //   this.messageData.profession = this.professions.find(item => item.value === this.messageData.profession);
      // }
      // if (!/[A-Za-z]/.test(this.messageData.job)) {
      //   this.messageData.job = this.jobs.find(item => item.value === this.messageData.job);
      // }
      this.$refs['messageData'].validate((valid) => {
        if (valid) {
          MessageService.updateDetail(this.messageData).then(res => {
            if (res.state) {
              this.$message.success(res.message);
              this.closeButton();
            } else {
              this.$message.warning(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 字典初始化
    getDic(){
      // 院校名称
      dictionary.getDicByCode("academy").then(res => {
        if (res.state){
          this.academys = res.data;
        }
      })
      // 专业名称
      dictionary.getDicByCode("profession").then(res => {
        if (res.state){
          this.professions = res.data;
        }
      })
      //职务名称
      dictionary.getDicByCode("team_job").then(res => {
        if (res.state){
          this.jobs = res.data
        }
      })
    },
    load() {
      if (this.userDetailId === '') {
        const userDetailId1 = localStorage.getItem("userDetailId");
        const userName = localStorage.getItem("userName");
        MessageService.queryDetail(userDetailId1).then(res => {
          if (res.state) {
            this.messageData = res.data;
            this.messageData.name = userName;
            this.isBelong = this.messageData.isBelong;
            this.isManager = this.messageData.isManage === "1";
            this.isTeacher = this.messageData.roleId === "1002";
            this.isImage = this.messageData.imageUrl;
          } else {
            self.$message.warning(res.message);
          }
        })
      } else {
        MessageService.queryDetail(this.userDetailId).then(res => {
          if (res.state) {
            this.messageData = res.data;
            this.messageData.name = this.userName;
            this.isBelong = this.messageData.isBelong;
            this.isManager = this.messageData.isManage === "1";
            this.isTeacher = this.messageData.roleId === "1002";
            this.isImage = this.messageData.imageUrl;
          } else {
            self.$message.warning(res.message);
          }
        })
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.imageUrl;
      this.dialogVisibleImage = true;
    },
    closeButton() {
      this.isShow = true;
      const isSubmit = document.getElementById("submit")
      isSubmit.style.display = "none";
      const cancel = document.getElementById("cancel")
      cancel.style.display = "none";
    },
    convert() {
      if (this.messageData.job && this.oldJob !== this.messageData.job) {
        this.messageData.job = this.messageData.job.toString()
      }
      if (this.cascaderValue && this.oldPlace !== this.cascaderValue.toString()) {
        const province = codeToText[this.cascaderValue[0]];
        const city = codeToText[this.cascaderValue[1]];
        this.messageData.nativePlace = `${province}/${city}`;
      }
    },
    cancel() {
      this.convert();
      this.closeButton();
      window.location.reload();
    },
    onCreated1(editor1) {
      this.editor1 = Object.seal(editor1) // 一定要用 Object.seal() ，否则会报错
    }, onCreated2(editor2) {
      this.editor2 = Object.seal(editor2) // 一定要用 Object.seal() ，否则会报错
    }, onCreated3(editor3) {
      this.editor3 = Object.seal(editor3) // 一定要用 Object.seal() ，否则会报错
    },
    handleChange(value) {

    },
    // 文件上传
    handleRemove(file, fileList) {
    },
    handleFileSuccess(response, file, fileList) {
      this.messageData.imageUrl = response.data;
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return false;
      }

      let form = new FormData()
      form.append("file", file);
      MessageService.uploadFile(form).then(res => {
        this.messageData.imageUrl = res.data;
      })
      return false;
    },
    upload(pic) {
      window.open(this.baseUrl + pic, '_blank')
    },
  },
  validatorStudentId(rule, value, callback) {
    if (this.isBelong !== 0) {
      if (/^-/.test(value)) {
        callback(new Error("输入不能为负数"))
      } else if (!/^\d+$/.test(value)) {
        callback(new Error("学号只能包含数字"))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style>
.header {
  background-color: white;
  color: black;
  border: #f6f6f8 solid;
  font-weight: bold;
  font-size: 20px;
  box-sizing: border-box;
  height: 70px;
  line-height: 65px;
  margin: 0
}

.header-span {
  margin-left: 70px;
}

.header-edit {
  margin-left: 75%;
}

.button2 {
  display: flex;
  flex-direction: row;
  margin-left: 80%;
}

.formTable {
  padding: 20px;
}

.image_member {
  width: 120px;
  height: 150px;
}
</style>
