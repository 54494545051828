<template>
  <div  class="research_dialog">
    <el-dialog width="50%" :title="diaTitle" :visible.sync="dialogVisb" @close="handleClose" :close-on-click-modal="false">
      <el-form :model="data" label-width="120px" ref="researchData" :rules="rules">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="成果年份" prop="researchYear">
              <div class="block">
                <el-date-picker
                    style="width: 100%"
                    :disabled="disableOrNot"
                    v-model="data.researchYear"
                    type="year"
                    value-format="yyyy"
                    placeholder="请选择成果年份">
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="成果类型" prop="type">
              <el-select :disabled="disableOrNot" v-model="data.type" style="width: 100%">
                <el-option
                    v-for="item in researches"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="成果名称" prop="name">
              <el-input :disabled="disableOrNot" v-model="data.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="录用/申请日期" prop="applyDate">
              <div class="block">
                <el-date-picker
                    style="width: 100%"
                    :disabled="disableOrNot"
                    v-model="data.applyDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择录用/申请日期">
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="见刊/授权日期" prop="publishDate">
              <div class="block">
                <el-date-picker
                    style="width: 100%"
                    :disabled="disableOrNot"
                    v-model="data.publishDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择见刊/授权日期">
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="学生姓名" prop="studentId">
              <student-tree
                  style="width: 100%"
                  :disableOrNot="disableOrNot"
                  :existStudentIds="existStudentIds"
                  :studentData="studentDataAll"
                  :studentDataProps="{id: 'id',label: 'label',children: 'children'}"
                  @update:userIds="handleUpdateStudent"
                  placeholder="请选择学生姓名"
                  class="position-select"
                  size="mini"
                  filterable>
              </student-tree>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="指导教师" prop="teacherId">
              <teacher-tree
                  style="width: 100%"
                  :disableOrNot="disableOrNot"
                  :existTeacherIds="existTeacherIds"
                  :teacherData="teacherDataAll"
                  :teacherDataProps="{id: 'id',label: 'label',children: 'children'}"
                  @update:userIds="handleUpdateTeacher"
                  placeholder="请选择老师姓名"
                  class="position-select"
                  size="mini"
                  filterable>
              </teacher-tree>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="成果图片" v-if="imageIsNone">
              <el-upload v-if="type === 'edit' || type === 'add'"
                         :on-preview="upload"
                         :on-remove="handleRemoveImage"
                         :before-upload="beforeUpload"
                         :file-list="imageList"
                         action
                         multiple>
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <el-link
                  v-if="type === 'detail' && isImage"
                  class="mr30"
                  style="display: block"
                  :href="baseUrl + data.imageUrl"
                  type="primary"
                  target="_blank"
              >{{ data.imageUrl.match(/[^\/]+$/)[0] }}
              </el-link>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="成果文件" v-if="fileIsNone">
              <el-upload v-if="type === 'edit' || type === 'add'"
                         :on-preview="handlePictureCardPreview"
                         :on-remove="handleRemoveFile"
                         :before-upload="beforeUpload2"
                         :file-list="fileList"
                         action
                         multiple>
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <el-link
                  v-if="type === 'detail'"
                  class="mr30"
                  style="display: block"
                  :href="baseUrl + data.fileUrl"
                  type="primary"
                  target="_blank"
              >{{ data.fileName }}
              </el-link>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="修改人" v-if="disableOrNot">
              <el-input :disabled="disableOrNot" v-model="data.updatedBy"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="修改时间" v-if="disableOrNot">
              <el-input :disabled="disableOrNot" v-model="data.updationDate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: right">
        <el-button @click="handleClose()">取消</el-button>
        <el-button v-if="!disableOrNot" type="primary" @click="save()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ResearchService from '../../../../api/research'
import {nanoid} from "nanoid";
import TeacherTree from "@/components/tree/TeacherTree";
import StudentTree from "@/components/tree/StudentTree";
import manageService from "@/api/manage";
import dictionary from "@/api/dictionary";
import { BASE_URL } from '@/utils/constant';

export default {
  name: 'AddOrModifyDialog',
  components: {StudentTree, TeacherTree},
  props: {
    type: String,
    researchId: String,
    dialogFormVisible: Boolean,
  },
  data() {
    return {
      disableOrNot: false,
      diaTitle: '',
      data: {},
      isImage: false,
      rules: {
        type: [{
          required: true,
          message: '成果类型不能为空',
          trigger: 'blur'
        }],
        researchYear: [{
          required: true,
          message: '成果年份不能为空',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '成果名称不能为空',
          trigger: 'blur'
        }],
        applyDate: [{
          required: true,
          message: '录用/申请日期不能为空',
          trigger: 'blur'
        }],
        publishDate: [{
          required: true,
          message: '见刊/授权日期不能为空',
          trigger: 'blur'
        }]
      },
      imageList: [],
      fileList: [],
      baseUrl: BASE_URL,
      imageIsNone: true,
      fileIsNone: true,
      studentIds: [],
      teacherIds: [],
      existStudentIds: [],
      studentDataAll: [],
      existTeacherIds: [],
      teacherDataAll: [],
      researches: [],
    }
  },
  watch: {
    type: {
      handler(newval, oldval) {
        if (newval === 'add') {
          this.diaTitle = '新增'
          this.disableOrNot = false
        }
        if (newval === 'edit') {
          this.diaTitle = '编辑'
          this.disableOrNot = false
        }
        if (newval === 'detail') {
          this.diaTitle = '详情'
          this.disableOrNot = true
        }
      },
      immediate: true,
    },
  },
  computed: {
    dialogVisb: {
      get() {
        return this.dialogFormVisible
      },
      set(v) {
        this.$emit('update:dialogFormVisible', v)
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      this.$nextTick(() => {
        this.searchAllUsers().then(res => {
          this.getDic();
          if (this.researchId && this.researchId !== '') {
            ResearchService.queryById(this.researchId).then((res) => {
              if (res.state) {
                this.data = res.data;
                this.data.researchYear = '' + this.data.researchYear;
                this.existStudentIds = res.data.userInfos.filter(item => item.roleId === "1003").map(item => item.userId);
                this.existTeacherIds = res.data.userInfos.filter(item => item.roleId === "1002").map(item => item.userId);
                if (this.data.imageUrl && this.data.imageUrl !== "") {
                  const item = {
                    imageUrl: this.data.imageUrl,
                    name: this.data.imageUrl.match(/[^\/]+$/)[0]
                  };
                  this.isImage = true;
                  this.imageList.push(item);
                }
                if (this.data.fileUrl && this.data.fileUrl !== "") {
                  const item = {
                    fileUrl: this.data.fileUrl,
                    name: this.data.fileName
                  };
                  this.fileList.push(item);
                }
                if (this.type === "detail") {
                  if (this.imageList && this.imageList.length === 0) {
                    this.imageIsNone = false;
                  }
                  if (this.fileList && this.fileList.length === 0) {
                    this.fileIsNone = false;
                  }
                }
              }
            })
          }
        })
      });
    },
    searchAllUsers() {
      return new Promise((resolve, reject) => {
        manageService.searchAllUsers().then((res) => {
          this.studentDataAll = this.studentListToTree(res.data);
          this.teacherDataAll = this.teacherListToTree(res.data);
          resolve();
        })
      })
    },
    save() {
      this.data.userIds = this.studentIds.concat(this.teacherIds);
      this.$refs['researchData'].validate((valid) => {
        if (valid) {
          if (this.type === 'add') {
            ResearchService.insertOrUpdate(this.data).then((res) => {
              if (res.state) {
                this.$message.success('新增成功')
                this.handleClose()
                this.$emit('refresh')
              }
            })
          }
          if (this.type === 'edit') {
            ResearchService.insertOrUpdate(this.data).then((res) => {
              if (res.state) {
                this.$message.success('修改成功')
                this.handleClose()
                this.$emit('refresh')
              }
            })
          }
        } else {
          return false;
        }
      })
    },
    // 字典初始化
    getDic(){
      // 研究成果名称
      dictionary.getDicByCode("research_achievement").then(res => {
        if (res.state){
          this.researches = res.data;
        }
      })
    },
    handleUpdateStudent(newId) {
      this.studentIds = newId;
    },
    handleUpdateTeacher(newId) {
      this.teacherIds = newId;
    },
    upload(file) {
      window.open(this.baseUrl + file.imageUrl, '_blank')
    },
    handlePictureCardPreview(file) {
      window.open(this.baseUrl + file.fileUrl, '_blank')
    },
    handleRemoveImage() {
      this.data.imageUrl = "";
    },
    handleRemoveFile() {
      this.data.fileUrl = "";
    },
    //上传图片
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return false;
      }

      let form = new FormData()
      form.append("file", file);
      ResearchService.uploadImage(form).then(res => {
        const item = {
          imageUrl: res.data,
          name: res.data.match(/[^\/]+$/)[0],
          uid: nanoid()
        };
        this.imageList.push(item);
        this.data.imageUrl = res.data;
      });
      return false;
    },
    beforeUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!')
        return false;
      }

      let form = new FormData()
      form.append("file", file);
      ResearchService.uploadFile(form).then(res => {
        const item = {
          fileName: res.data.fileName,
          fileUrl: res.data.fileUrl,
          name: res.data.fileName,
          uid: nanoid()
        };
        this.fileList.push(item)
        this.data.fileUrl = res.data.fileUrl;
        this.data.fileName = res.data.fileName;
      });

      return false;
    },
    // 关闭对话框
    handleClose() {
      this.existStudentIds = []
      this.existTeacherIds = []
      this.data = {}
      this.dialogVisb = false
    },
    // 学生树形结构数据
    studentListToTree(data) {

      let studentData = [];
      let professionMap = {}; // 用于快速查找专业节点
      let entryYearMap = {}; // 用于快速查找入学年份节点

      for (const student of data) {
        if (!student.profession || !student.entryYear || !student.id || !student.userName || student.roleId !== '1003') {
          continue; // 跳过缺失必要属性的学生对象
        }

        // 处理专业节点
        if (!professionMap[student.profession]) {
          const professionNode = this.createNode(this.createRandomId(), student.profession);
          studentData.push(professionNode);
          professionMap[student.profession] = professionNode;
          entryYearMap[student.profession] = {}; // 初始化当前专业的入学年份映射
        }

        // 处理入学年份节点
        const professionNode = professionMap[student.profession];
        if (!entryYearMap[student.profession][student.entryYear]) {
          const entryYearNode = this.createNode(this.createRandomId(), student.entryYear.toString());
          professionNode.children.push(entryYearNode);
          entryYearMap[student.profession][student.entryYear.toString()] = entryYearNode;
        }

        // 添加学生信息节点
        const entryYearNode = entryYearMap[student.profession][student.entryYear];
        entryYearNode.children.push(this.createNode(student.id, student.userName, true));
      }

      return studentData;
    },

    // 教师树形结构数据
    teacherListToTree(data) {
      let teacherData = [];
      let academyMap = {}; // 用于快速查找专业节点

      for (const teacher of data) {
        if (!teacher.id || !teacher.userName || !teacher.academy || teacher.roleId !== '1002') {
          continue;
        }

        if (!academyMap[teacher.academy]) {
          const academyNode = this.createNode(this.createRandomId(), teacher.academy);
          teacherData.push(academyNode);
          academyMap[teacher.academy] = academyNode;
        }

        const teacherNode = academyMap[teacher.academy];
        teacherNode.children.push(this.createNode(teacher.id, teacher.userName, true));
      }
      return teacherData;
    },

    // 创建树节点
    createNode(id, label, isLeaf = false) {
      return {
        id,
        label,
        children: [],
        ...(isLeaf && {isLeaf: true}), // 只有叶子节点才添加isLeaf属性
      };
    },

    // 生成一个随机的字符串作为 ID
    createRandomId() {
      return Math.random().toString(36).substr(2, 9);
    }
  },
}
</script>
<style scoped>
.research_dialog {
  margin-top: 8vh;
}
</style>
