<template>
  <div>
    <el-dialog width="40%" :title="diaTitle" :visible.sync="dialogVisb" @close="handleClose" :close-on-click-modal="false">
      <el-form :model="data" label-width="80px" ref="deviceData" :rules="rules">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="设备名称" prop="name">
              <el-input :disabled="disableOrNot" v-model="data.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="设备类型" prop="type">
              <el-select :disabled="disableOrNot" v-model="data.type" style="width: 100%">
                <el-option
                    v-for="item in types"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="使用情况" prop="useCondition">
              <el-select :disabled="disableOrNot" v-model="data.useCondition" style="width: 100%">
                <el-option
                    v-for="item in conditions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="持有用户" prop="userId">
              <student-tree
                  style="width: 100%"
                  :disableOrNot="disableOrNot"
                  :existStudentIds="existStudentIds"
                  :studentData="studentDataAll"
                  :studentDataProps="{id: 'id',label: 'label',children: 'children'}"
                  @update:userIds="handleUpdateStudent"
                  placeholder="请选择学生姓名"
                  class="position-select"
                  size="mini"
                  filterable>
              </student-tree>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="设备数量" prop="number">
              <el-input :disabled="disableOrNot" v-model="data.number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="修改人" v-if="disableOrNot">
              <el-input :disabled="disableOrNot" v-model="data.updatedBy"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="修改时间" v-if="disableOrNot">
              <el-input :disabled="disableOrNot" v-model="data.updationDate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: right">
        <el-button @click="handleClose()">取消</el-button>
        <el-button v-if="!disableOrNot" type="primary" @click="save()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { BASE_URL } from '@/utils/constant';
import dictionary from "@/api/dictionary";
import DeviceService from "../../../../../api/device"
import manageService from "@/api/manage";
import StudentTree from "@/components/tree/StudentTree";

export default {
  name: 'AddOrModifyDialog',
  components: {StudentTree},
  props: {
    type: String,
    deviceRecordId: String,
    dialogFormVisible: Boolean,
  },
  data() {
    return {
      disableOrNot: false,
      diaTitle: '',
      data: {},
      buyData: [],
      rules: {
        name: [{
          required: true,
          message: '设备名称不能为空',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '设备类型不能为空',
          trigger: 'blur'
        }],
        useCondition: [{
          required: true,
          message: '使用情况不能为空',
          trigger: 'blur'
        }]
      },
      baseUrl : BASE_URL,
      imageIsNone: true,
      imageList: [],
      activities: [],
      conditions: [],
      types: [],
      existStudentIds: [],
      studentDataAll: [],
      studentId: '',
      isBuy: false,
    }
  },
  watch: {
    type: {
      handler(newval, oldval) {
        if (newval === 'add') {
          this.diaTitle = '新增'
          this.disableOrNot = false
        }
        if (newval === 'edit') {
          this.diaTitle = '编辑'
          this.disableOrNot = false
          this.isBuy = true;
        }
        if (newval === 'detail') {
          this.diaTitle = '详情'
          this.disableOrNot = true
          this.isBuy = true;
        }
      },
      immediate: true,
    },
  },
  computed: {
    dialogVisb: {
      get() {
        return this.dialogFormVisible
      },
      set(v) {
        this.$emit('update:dialogFormVisible', v)
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      this.$nextTick(() => {
        this.searchAllUsers().then(res => {
          this.getDic();
          if (this.deviceRecordId && this.deviceRecordId !== '') {
            DeviceService.getByDeviceRecordId(this.deviceRecordId).then((res) => {
              if (res.state) {
                this.data = res.data;
                this.existStudentIds = [this.data.userId];
                if (this.type === "detail") {
                  if (!this.data.imageUrl) {
                    this.imageIsNone = false;
                  }
                }
              }
            })
          }
        })
      })
    },
    save() {
      this.data.userId = this.studentId;
      this.$refs['deviceData'].validate((valid) => {
        if (valid) {
          if (this.type === 'add') {
            DeviceService.insertOrUpdateDeviceRecord(this.data).then((res) => {
              if (res.state) {
                this.$message.success('新增成功')
                this.handleClose()
                this.$emit('refresh')
              }
            })
          }
          if (this.type === 'edit') {
            DeviceService.insertOrUpdateDeviceRecord(this.data).then((res) => {
              if (res.state) {
                this.$message.success('修改成功')
                this.handleClose()
                this.$emit('refresh')
              }
            })
          }
        } else {
          return false;
        }
      })
    },
    searchAllUsers() {
      return new Promise((resolve, reject) => {
        manageService.searchAllUsers().then((res) => {
          this.studentDataAll = this.studentListToTree(res.data);
          resolve();
        })
      })
    },
    handleUpdateStudent(newId) {
      this.studentId = newId.join(", ");
    },
    // 字典初始化
    getDic(){
      // 使用情况名称
      dictionary.getDicByCode("use_condition").then(res => {
        if (res.state){
          this.conditions = res.data;
        }
      })
      dictionary.getDicByCode("device_type").then(res => {
        if (res.state){
          this.types = res.data;
        }
      })
    },
    handleClose() {
      this.data = {}
      this.dialogVisb = false
    },
    // 学生树形结构数据
    studentListToTree(data) {

      let studentData = [];
      let professionMap = {}; // 用于快速查找专业节点
      let entryYearMap = {}; // 用于快速查找入学年份节点

      for (const student of data) {
        if (!student.profession || !student.entryYear || !student.id || !student.userName || student.roleId !== '1003') {
          continue; // 跳过缺失必要属性的学生对象
        }

        // 处理专业节点
        if (!professionMap[student.profession]) {
          const professionNode = this.createNode(this.createRandomId(), student.profession);
          studentData.push(professionNode);
          professionMap[student.profession] = professionNode;
          entryYearMap[student.profession] = {}; // 初始化当前专业的入学年份映射
        }

        // 处理入学年份节点
        const professionNode = professionMap[student.profession];
        if (!entryYearMap[student.profession][student.entryYear]) {
          const entryYearNode = this.createNode(this.createRandomId(), student.entryYear.toString());
          professionNode.children.push(entryYearNode);
          entryYearMap[student.profession][student.entryYear.toString()] = entryYearNode;
        }

        // 添加学生信息节点
        const entryYearNode = entryYearMap[student.profession][student.entryYear];
        entryYearNode.children.push(this.createNode(student.id, student.userName, true));
      }

      return studentData;
    },
    // 创建树节点
    createNode(id, label, isLeaf = false) {
      return {
        id,
        label,
        children: [],
        ...(isLeaf && {isLeaf: true}), // 只有叶子节点才添加isLeaf属性
      };
    },
    // 生成一个随机的字符串作为 ID
    createRandomId() {
      return Math.random().toString(36).substr(2, 9);
    }
  },
}
</script>
<style scoped>
.buy_header {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buy_h1 {
  line-height: 18px;
  font-size: 18px;
  font-weight: bolder;
  color: #303133;
}

</style>
