<template>
  <div class="wareHouseBody">
    <div class="flex-column height100">
      <div class="searchBox">
        <el-form label-width="72px">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="比赛年份">
                <el-select v-model="searchParams.params.competitionYear" style="width: 100%" @change="changeName">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in years"
                      :key="item.value"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="比赛名称">
                <el-select v-model="searchParams.params.competitionId" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in competitions"
                      :key="item.id"
                      :label="item.competitionName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目名称">
                <project-tree
                    v-model="searchParams.params.projectId"
                    :project-data="projectDataAll"
                    :existProjectId="existProjectId"
                    :project-data-props="{value: 'value',label: 'label',children: 'children'}"
                    placeholder="请选择项目名称"
                    size="mini"
                    @update:projectId="handleUpdate"
                    filterable
                    style="width: 100%">
                </project-tree>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学生姓名">
                <student-tree
                    style="width: 100%"
                    :studentData="studentDataAll"
                    :existStudentIds="existStudentIds"
                    :studentDataProps="{value: 'value',label: 'label',children: 'children'}"
                    @update:userIds="handleUpdateStudent"
                    placeholder="请选择学生姓名"
                    class="position-select"
                    size="mini"
                    filterable>
                </student-tree>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="指导教师">
                <teacher-tree
                    style="width: 100%"
                    :teacherData="teacherDataAll"
                    :existTeacherIds="existTeacherIds"
                    :teacherDataProps="{value: 'value',label: 'label',children: 'children'}"
                    @update:userIds="handleUpdateTeacher"
                    placeholder="请选择老师姓名"
                    class="position-select"
                    size="mini"
                    clearable
                    filterable>
                </teacher-tree>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="国/省赛">
                <el-select v-model="searchParams.params.level" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in levels"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="获奖等级">
                <el-select v-model="searchParams.params.grade" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                      v-for="item in grades"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24" type="flex" align="middle" style="margin-left: -10px">
            <el-col :span="15">
              <el-button type="primary" @click="handleAdd()" v-btn-permission="'user_add'" v-if="isShowButton === '1'">新增</el-button>
              <el-button type="primary" @click="exportFile()" :loading="exportLoading">
                导出
              </el-button>
              <el-button type="primary" @click="del()" v-btn-permission="'user_delete'" :disabled="disabledType" v-if="isShowButton === '1'">
                批量删除
              </el-button>
              <el-button type="primary" @click="searchList()">查询</el-button>
              <el-button type="primary" @click="reset()">重置</el-button>
            </el-col>
<!--            <el-col :span="12" style="display: flex; justify-content: flex-end; align-items: flex-end;">-->
<!--              <div style="position: relative;">-->
<!--                tip:证书下载按钮右上角出现<span style="color: red">*</span>表示该奖项证书尚未上传-->
<!--              </div>-->
<!--            </el-col>-->
          </el-row>
        </el-form>
      </div>

      <div class="tableData" style="height: 100%">
        <el-table
            ref="filterTable"
            :data="awards"
            :show-overflow-tooltip="true"
            @selection-change="handleSelectionChange"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="competitionYear" label="比赛年份" min-width="100"/>
          <el-table-column prop="competitionName" label="比赛名称" min-width="400" show-overflow-tooltip/>
          <el-table-column prop="projectName" label="项目名称" min-width="150"/>
          <el-table-column
              prop="level"
              label="国赛/省赛"
              min-width="100"
              :formatter="convertLevel"
              :filters="[{ text: '国赛', value: 1 }, { text: '省赛', value: 2 }]"
              :filter-method="filterHandler"/>
          <el-table-column
              prop="grade"
              label="获奖等级"
              min-width="100"
              :formatter="convertGrade"
              :filters="[{ text: '特等奖', value: 0 }, { text: '一等奖', value: 1 }, { text: '二等奖', value: 2 }, { text: '三等奖', value: 3 }]"
              :filter-method="filterHandler"/>
          <el-table-column prop="studentName" label="学生姓名" min-width="200"/>
          <el-table-column prop="teacherName" label="指导教师" min-width="150"/>
          <el-table-column label="操作" min-width="150" align="center" v-if="isShowButton === '1'">
            <template slot-scope="scope">
              <el-button type="text" @click="viewItem(scope.row)">查看</el-button>
              <el-button @click="handleEdit(scope.row)" size="mini" v-btn-permission="'user_edit'">编辑</el-button>
            </template>
          </el-table-column>
          <el-table-column label="获奖证书" min-width="80">
            <template slot-scope="scope">
              <template v-if="!scope.row.imageUrl">
                <el-badge is-dot class="badge">
                  <el-button @click="download(scope.row)" size="mini">下载</el-button>
                </el-badge>
              </template>
              <template v-else>
                <el-button @click="download(scope.row)" size="mini">下载</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            style="text-align: right;"
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page="this.searchParams.pageBean.pageNum"
            :page-size="this.searchParams.pageBean.pageSize"
            :page-sizes="pageSizes" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <AddOrModifyDialog
        v-if="dialogFormVisible"
        :dialogFormVisible.sync="dialogFormVisible"
        :awardId="awardId"
        :type="type"
        @refresh="load"/>
  </div>
</template>

<script>

import {processDataFlow} from "@/utils";
import awardsService from "@/api/awards";
import AddOrModifyDialog from "./components/AddOrModify.vue";
import competitionService from "@/api/competition";
import projectService from "@/api/project";
import projectTree from "@/components/tree/ProjectTree";
import StudentTree from "@/components/tree/StudentTree";
import manageService from "@/api/manage";
import TeacherTree from "@/components/tree/TeacherTree";
import dictionary from "@/api/dictionary";
import { BASE_URL } from '@/utils/constant';

export default {
  name: 'Manage',
  components: {AddOrModifyDialog, projectTree, StudentTree, TeacherTree},
  props: {
    projectId: {
      type: String,
      default: "",
    },
    competitionId: {
      type: String,
      default: "",
    },
    competitionYear: {
      type: String,
      default: "",
    },
    isShowButton: {
      type: String,
      default: "1",
    }
  },
  data() {
    return {
      total: 0, //总条目数
      tableData: [],
      awardId: '',
      type: '',
      years: [],
      competitions: [],
      projectNames: [],
      multipleSelection: [],
      awardsData: {},
      pageSizes: [10, 20, 30, 50, 100], //可选择的一页多少条
      dialogFormVisible: false,
      exportLoading: false,
      disabledType: true,
      tableLoading: false,
      projectDataAll: [],
      levelMap: new Map([
        [1, '国赛'],
        [2, '省赛']
      ]),
      gradeMap: new Map([
        [0, '特等奖'],
        [1, '一等奖'],
        [2, '二等奖'],
        [3, '三等奖']
      ]),
      levels: [],
      grades: [],
      awards: [],
      studentIds:[],
      teacherIds:[],
      existProjectId: [],
      existStudentIds:[],
      existTeacherIds:[],
      searchParams: {
        pageBean: {
          pageNum: 1,
          pageSize: 10
        },
        params: {
          competitionYear: this.competitionYear,
          competitionId: this.competitionId,
          projectId: this.projectId,
          userIds: [],
          level:'',
          grade:'',
        },
      },
      studentDataAll: [],
      teacherDataAll: [],
    }
  },
  watch: {
    multipleSelection(val) {
      if (val.length > 0) {
        this.disabledType = false;
      } else {
        this.disabledType = true;
      }
    },
    competitionYear: {
      handler(newval, oldval) {
        if (newval) {
          competitionService.searchByYear(this.competitionYear).then((res) => {
            if (res.state) {
              this.competitions = res.data;
            }
          })
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      this.searchAllYears();
      this.searchAllProjects();
      this.$nextTick(() => {
        this.searchAllUsers().then(res => {
          setTimeout(() => {
            this.existProjectId = [this.projectId];
          }, 30);
        });
      })
      this.load();
      this.getDic();
    },
    searchList() {
      this.searchParams.params.userIds = this.studentIds.concat(this.teacherIds);
      this.load(1);
    },
    convertLevel(row, column, cellValue) {
      return this.levelMap.get(cellValue) || cellValue;
    },
    convertGrade(row, column, cellValue) {
      return this.gradeMap.get(cellValue) || cellValue;
    },
    searchAllProjects() {
      projectService.searchProjectName().then(res => {
        this.projectDataAll = this.listToTree(res.data);
      })
    },
    searchAllYears() {
      competitionService.searchAllYears().then(res => {
        this.years = res.data;
      })
    },
    searchAllUsers() {
      return new Promise((resolve, reject) => {
        manageService.searchAllUsers().then((res) => {
          this.studentDataAll = this.studentListToTree(res.data);
          this.teacherDataAll = this.teacherListToTree(res.data);
          resolve();
        })
      })
    },
    changeName() {
      this.searchParams.params.competitionId = '';
      if (this.searchParams.params.competitionYear && this.searchParams.params.competitionYear !== "") {
        competitionService.searchByYear(this.searchParams.params.competitionYear).then((res) => {
          if (res.state) {
            this.competitions = res.data;
          }
        })
      } else {
        this.competitions = [];
      }
    },
    load(number) {
      if (number === 1) {
        this.searchParams.pageBean.pageNum = 1;
      }
      this.tableLoading = true
      awardsService.searchList(this.searchParams)
          .then(res => {
            this.awards = res.rows;
            this.total = res.total;
          }).finally(() => {
        this.tableLoading = false
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    reset() {
      this.searchParams = {
        pageBean: {
          pageNum: 1,
          pageSize: 10
        },
        params: {
          competitionYear: '',
          competitionId: '',
          projectId: '',
          userIds: [],
          level: '',
          grade: ''
        },
      }
      this.existProjectId = [];
      this.existStudentIds = [];
      this.existTeacherIds = [];
      this.$refs.filterTable.clearFilter();
      this.load();
    },
    del() {
      let ids = this.multipleSelection.map((item) => item.id).join(",");
      let self = this;
      self
          .$confirm("此操作将删除已选择数据, 是否继续?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            awardsService.delete(ids)
                .then((res) => {
                  if (res.state) {
                    self.$message.success(res.message);
                  } else {
                    self.$message.error(res.message);
                  }
                }).finally(() => {
              self.load();
            });
          });
    },
    // 字典初始化
    getDic(){
      // 获奖等级
      dictionary.getDicByCode("award_level").then(res => {
        if (res.state){
          this.grades = res.data;
        }
      })
      // 赛事等级
      dictionary.getDicByCode("competition_level").then(res => {
        if (res.state){
          this.levels= res.data;
        }
      })
    },
    exportFile() {
      awardsService.export(this.searchParams)
          .then(res => {
            processDataFlow(res, '奖状信息汇总表.xlsx')
          })
          .finally(() => {
            this.exportLoading = false
          })
    },
    handleAdd() {
      this.type = "add";
      this.awardId = undefined;
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      this.type = 'edit';
      this.awardId = row.id;
      this.dialogFormVisible = true
    },
    viewItem(row) {
      this.type = 'detail';
      this.awardId = row.id;
      this.dialogFormVisible = true
    },

    // 下载证书
    download(row){
      // if (!row.imageUrl || row.imageUrl === ''){
      //   this.$message.warning("该奖项尚未上传证书");
      //   return;
      // }
      // let imageName;
      // if (row.studentName && row.studentName !== ''){
      //   imageName = row.competitionName + row.studentName + ".jpg";
      // }else {
      //   imageName = row.competitionName + ".jpg";
      // }
      // awardsService.download(row.imageUrl)
      //     .then(res => {
      //       processDataFlow(res,imageName)
      //     })
      // 假设 row 对象包含一个 imageUrl 属性，表示要下载的文件 URL
      const url = row.imageUrl; // 根据实际数据结构调整属性名

      // 创建一个链接元素
      const link = document.createElement('a');
      link.href = url;
      window.open(BASE_URL + url, '_blank');
    },

    handleUpdateStudent(newId) {
      this.studentIds = newId;
    },
    handleUpdateTeacher(newId) {
      this.teacherIds = newId;
    },
    handleUpdate(newId) {
      this.searchParams.params.projectId = newId;
    },

    //page改变时的回调函数，参数为当前页码
    currentChange(val) {
      this.searchParams.pageBean.pageNum = val;
      this.load();
    },
    //size改变时回调的函数，参数为当前的size
    sizeChange(val) {
      this.searchParams.pageBean.pageSize = val;
      this.load();
    },
    //筛选
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },

    listToTree(data) {

      let projectData = [];
      let yearMap = {}; // 用于快速查找年份节点

      for (const project of data) {

        // 处理年份节点
        if (!yearMap[project.projectYear.toString()]) {
          const yearNode = this.createNode(this.createRandomId(), project.projectYear.toString());
          projectData.push(yearNode);
          yearMap[project.projectYear.toString()] = yearNode;
        }

        // 添加项目信息节点
        const projectNode = yearMap[project.projectYear.toString()];
        projectNode.children.push(this.createNode(project.id, project.projectName, true));
      }

      return projectData;
    },
    // 人员树
    studentListToTree(data) {

      let studentData = [];
      let professionMap = {}; // 用于快速查找专业节点
      let entryYearMap = {}; // 用于快速查找入学年份节点

      for (const student of data) {
        if (!student.profession || !student.entryYear || !student.id || !student.userName || student.roleId !== '1003') {
          continue; // 跳过缺失必要属性的学生对象
        }

        // 处理专业节点
        if (!professionMap[student.profession]) {
          const professionNode = this.createNode(this.createRandomId(), student.profession);
          studentData.push(professionNode);
          professionMap[student.profession] = professionNode;
          entryYearMap[student.profession] = {}; // 初始化当前专业的入学年份映射
        }

        // 处理入学年份节点
        const professionNode = professionMap[student.profession];
        if (!entryYearMap[student.profession][student.entryYear]) {
          const entryYearNode = this.createNode(this.createRandomId(), student.entryYear.toString());
          professionNode.children.push(entryYearNode);
          entryYearMap[student.profession][student.entryYear.toString()] = entryYearNode;
        }

        // 添加学生信息节点
        const entryYearNode = entryYearMap[student.profession][student.entryYear];
        entryYearNode.children.push(this.createNode(student.id, student.userName, true));
      }

      return studentData;
    },

    teacherListToTree(data) {
      let teacherData = [];
      let academyMap = {}; // 用于快速查找专业节点

      for (const teacher of data) {
        if (!teacher.id || !teacher.userName || !teacher.academy || teacher.roleId !== '1002') {
          continue;
        }

        if (!academyMap[teacher.academy]) {
          const academyNode = this.createNode(this.createRandomId(), teacher.academy);
          teacherData.push(academyNode);
          academyMap[teacher.academy] = academyNode;
        }

        const teacherNode = academyMap[teacher.academy];
        teacherNode.children.push(this.createNode(teacher.id, teacher.userName, true));
      }
      return teacherData;
    },

    // 创建树节点
    createNode(id, label, isLeaf = false) {
      return {
        id,
        label,
        children: [],
        ...(isLeaf && {isLeaf: true}), // 只有叶子节点才添加isLeaf属性
      };
    },

    // 生成一个随机的字符串作为 ID
    createRandomId() {
      return Math.random().toString(36).substr(2, 9);
    }
  }
}
</script>

<style scoped lang="scss">

//.wareHouseBody {
//  margin: 0;
//  height: 100%;
//  //padding-bottom: 0.0625rem;
//  //min-height: calc(60vh - 6.4rem);
//  // ::v-deep .el-table .cell {
//  //   line-height: 32px;
//  //   white-space: normal !important;
//  //   &.el-tooltip {
//  //     white-space: nowrap !important;
//  //   }
//  // }
//}

.flex-column {
  display: flex;
  flex-direction: column;
}

//.height100 {
//  height: calc(120vh - 5rem);
//}

.searchBox {
  margin-top: 10px;
  padding: 20px;
}

.tableData {
  //margin-top: 20px;
  border-radius: 10px;
  border: thin solid #eaebec;
  width: 98%;
  margin-left: 1%;
}

.badge /deep/ .el-badge__content.is-fixed {
  transform: translateX(100%);
}

</style>
