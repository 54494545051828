<template>
  <el-aside :width="sideWidth+'px'" style="line-height: 100%;text-align:center;height: 100%">
    <h2 v-if="logoTextShow">月桐路</h2>
    <el-menu style="background-color: #079ec9;" :collapse="isCollapse" :collapse-transition="false">
      <router-link v-for="(item, index) in splitAdd" :to="`/${item.route}`" :key="index">
        <el-menu-item :index="index + 1" class="el-menu-item">
          <i :class="item.icon" style="color: white;"></i>
          <span :style="{ display: isCollapse ? 'none' : 'inline' }">{{ getMenuText(item.route) }}</span>
        </el-menu-item>
      </router-link>
      <el-submenu class="el-submenu-aside" v-if="deviceAndRecordRoutes.some(item => item.route === 'device' || item.route === 'record')" index="11">
        <template slot="title" class="el-submenu-title1">
          <i class="el-icon-s-claim" style="color:white;"></i>
          <span class="title1">设备管理</span>
        </template>
        <el-menu-item-group class="title_group_1">
          <router-link v-for="(item, index) in deviceAndRecordRoutes" :key="index" v-if="item.route === 'device' || item.route === 'record'" :to="`/${item.route}`" :id="item.route">
            <el-menu-item :index="'11-' + (index + 1)" class="el-menu-item_1">
              <i :class="item.icon" style="color:white;"></i>
              <span :style="{ display: isCollapse ? 'none' : 'inline' }">{{ getMenuText(item.route) }}</span>
            </el-menu-item>
          </router-link>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
    <span :class="collapseBtnClass" v-model="isCollapse" @click="collapse"
          style="font-size: 28px;cursor: pointer; position: fixed; bottom: 0;"></span>
  </el-aside>
</template>

<script>

export default {
  name: "Aside",
  data() {
    return {
      //菜单默认不折叠
      isCollapse: false,
      sideWidth: 300,
      logoTextShow: true,
      collapseBtnClass: "el-icon-d-arrow-left",
      splitAdd: [],
      deviceAndRecordRoutes: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.splitAdd = JSON.parse(localStorage.getItem('role')).splice(1);
      this.deviceAndRecordRoutes = this.splitAdd.filter(item => item.route === 'device' || item.route === 'record');
      this.splitAdd = this.splitAdd.filter(item => item.route !== 'device' && item.route !== 'record');
    },

    getMenuText(route) {
      switch (route) {
        case 'message':
          return '个人信息';
        case 'change':
          return '密码修改';
        case 'manage':
          return '成员管理';
        // case 'role':
        //   return '角色管理';
        // case 'permission':
        //   return '权限管理';
        case 'dictionary':
          return '字典管理';
        case 'project':
          return '项目';
        case 'competitions':
          return '比赛';
        case 'awards':
          return '荣誉';
        case 'activity':
          return '活动摄影';
        case 'research':
          return '科研成果';
        case 'device':
          return '设备信息';
        case 'record':
          return '历史记录';
        default:
          return '未知';
      }
    },

    collapse() {
      this.isCollapse = !this.isCollapse
      if (this.isCollapse) {
        this.init();
        this.sideWidth = 64
        this.collapseBtnClass = 'el-icon-d-arrow-right'
        this.logoTextShow = false;
      } else {
        this.init();
        this.sideWidth = 300
        this.collapseBtnClass = 'el-icon-d-arrow-left'
        this.logoTextShow = true;
      }
    }
  }
}
</script>

<style>
h2 {
  text-align: center;
  font-size: 50px;
  padding-top: 10px;
  color: white;
}

.el-menu-item {
  font-size: 20px;
  font-weight: normal;
  color: white;
}

.el-menu-item:hover span {
  color: #0a4ecc;
}

.title1 {
  color: white;
  font-size: 20px;
  font-weight: normal;
}

.title_group_1 {
  background-color: #079ec9;
}

.el-menu-item_1 {
  font-size: 16px;
}

.el-submenu-aside .el-icon-arrow-down {
  color: white; /* 更改箭头颜色 */
  font-size: 20px; /* 更改箭头大小 */
}

</style>
