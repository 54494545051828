<template>
  <div class="jq22-container" style="padding-top:100px">
    <div class="back_Login">
      <el-page-header style="color: white;" @back="back">
      </el-page-header>
    </div>
    <div class="login-wrap" >
      <div class="login-html">
        <input id="tab-1" type="radio" name="tab" class="sign-in" checked><label for="tab-1" class="tab">Login</label>
        <!--        <input id="tab-2" type="radio" name="tab" class="sign-up"><label for="tab-2" class="tab">Sign Up</label>-->
        <div class="login-form">
          <el-form class="sign-in-htm" v-model="loginForm" :rules="rules">
            <div class="group">
              <el-input v-model="loginForm.userName" type="text" class="input"
                        prefix-icon="el-icon-user-solid"></el-input>
              <label for="user" class="label">用户名</label>
            </div>
            <div class="group">
              <el-input v-model="loginForm.pwd" type="password" class="input" data-type="password"
                        prefix-icon="el-icon-lock"></el-input>
              <label for="pass" class="label">密码</label>
            </div>
            <div class="group">
              <el-button @click="onLogin()" style="margin-top: 30px" class="button">Login In</el-button>
            </div>
            <div class="hr"></div>
          </el-form>
          <!--          <el-form class="sign-up-htm" v-model="registerForm">-->
          <!--            <div class="group">-->
          <!--              <label for="user" class="label">用户名</label>-->
          <!--              <el-input v-model="registerForm.username" id="user" type="text" class="input"></el-input>-->
          <!--            </div>-->
          <!--            <div class="group">-->
          <!--              <label for="pass" class="label">密码</label>-->
          <!--              <el-input v-model="registerForm.password" id="pass" type="password" class="input" data-type="password"></el-input>-->
          <!--            </div>-->
          <!--            <div class="group">-->
          <!--              <label for="pass" class="label">身份</label>-->
          <!--              <el-input v-model="registerForm.identity" id="pass" type="password" class="input" data-type="password"></el-input>-->
          <!--            </div>-->
          <!--            <div class="group">-->
          <!--              <button @click="onSubmit" class="button" style="margin-top: 30px">Sign Up</button>-->
          <!--            </div>-->
          <!--            <div class="hr"></div>-->
          <!--            <div class="foot-lnk">-->
          <!--              <label for="tab-1">Already Member?</label>-->
          <!--            </div>-->
          <!--          </el-form>-->
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import loginService from "@/api/login";
import roleService from "@/utils/role";
import router, {filterRoute} from "@/router";
import routerList from "@/router/routingArray";

export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        userName: '',
        pwd: '',
      },
      registerForm: {},
      rules: {
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}
        ],
      }
    }
  },
  mounted() {
    const labels = document.querySelectorAll('.login-form .group .label')

    labels.forEach(label => {
      label.innerHTML = label.innerText
          .split('')
          .map((letter, idx) => `<span style="transition-delay:${idx * 50}ms">${letter}</span>`)
          .join('')
    });
    // 监听键盘按下事件
    document.addEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onSubmit() {

    },
    handleKeyDown(event) {
      if (event.keyCode === 13) {
        this.onLogin(); // 调用登录方法
      }
    },
    onLogin() {
      loginService.login(this.loginForm).then(res => {
        if (res.state) {
          localStorage.setItem('satoken', res.data.satoken);
          const splitAdd = res.data.permission.split(",");
          localStorage.setItem('permission', splitAdd);
          if (res.data.role === "teacher") {
            const role = [
              { route: 'main', icon: 'el-icon-message' },
              { route: 'message', icon: 'el-icon-message' },
              { route: 'change', icon: 'el-icon-chat-square' },
              { route: 'manage', icon: 'el-icon-s-management' },
              { route: 'awards', icon: 'el-icon-medal' },
              { route: 'competitions', icon: 'el-icon-monitor' },
              { route: 'project', icon: 'el-icon-folder' },
              { route: 'activity', icon: 'el-icon-apple' },
              { route: 'research', icon: 'el-icon-right' },
              { route: 'device', icon: 'el-icon-s-data' },
              { route: 'record', icon: 'el-icon-light-rain' }
            ];
            localStorage.setItem('role', JSON.stringify(role));
            const newRouterData = filterRoute(routerList, role);
            this.$router.options.routes.push(...newRouterData);
            router.addRoute(...newRouterData);
            this.$router.push("/message")
          }
          if (res.data.role === "student") {
            const role = [
              { route: 'main', icon: 'el-icon-message' },
              { route: 'message', icon: 'el-icon-message' },
              { route: 'change', icon: 'el-icon-chat-square' },
              { route: 'awards', icon: 'el-icon-medal' },
              { route: 'competitions', icon: 'el-icon-monitor' },
              { route: 'project', icon: 'el-icon-folder' },
              { route: 'activity', icon: 'el-icon-apple' },
              { route: 'research', icon: 'el-icon-right' },
              { route: 'device', icon: 'el-icon-s-data' },
              { route: 'record', icon: 'el-icon-light-rain' }
              ];
            localStorage.setItem('role', JSON.stringify(role));
            const newRouterData = filterRoute(routerList, role);
            this.$router.options.routes.push(...newRouterData);
            router.addRoute(...newRouterData);
            this.$router.push("/message")
          }
          if (res.data.role === "admin") {
            const role = [
              { route: 'main', icon: 'el-icon-message' },
              { route: 'message', icon: 'el-icon-message' },
              { route: 'change', icon: 'el-icon-chat-square' },
              { route: 'manage', icon: 'el-icon-s-management' },
              // { route: 'role', icon: 'el-icon-c-scale-to-original' },
              // { route: 'permission', icon: 'el-icon-cherry' },
              { route: 'dictionary', icon: 'el-icon-sunny' },
              { route: 'awards', icon: 'el-icon-medal' },
              { route: 'competitions', icon: 'el-icon-monitor' },
              { route: 'project', icon: 'el-icon-folder' },
              { route: 'activity', icon: 'el-icon-apple' },
              { route: 'research', icon: 'el-icon-right' },
              { route: 'device', icon: 'el-icon-s-data' },
              { route: 'record', icon: 'el-icon-light-rain' }
            ];
            localStorage.setItem('role', JSON.stringify(role));
            const newRouterData = filterRoute(routerList, role);
            this.$router.options.routes.push(...newRouterData);
            router.addRoute(...newRouterData);
            this.$router.push("/message")
          }
        }
      }).catch((error) => {
      });
    },
  }
}
</script>

<style>
@import "../../assets/css/login.css";
.back_Login {
  margin-top: -90px;
  margin-left: 10px;
  margin-bottom: 80px;
}
</style>
