<template>
  <div class="banner">
    <!-- 轮播图 -->
    <div class="box" >
      <carousel-3d style="margin: 0 auto" :autoplay="true" :autoplayTimeout="3000" :perspective="35" :display="5" :animationSpeed="1000"
                   :width="800" :height="400" controlsVisible :controlsHeight="60">
        <slide v-for="(item, i) in slides" :index="i" :key="i" class="boximg">
          <template slot-scope="obj" >
            <img :src="item.src" @click="imgClick(item, obj)"  />
          </template>
        </slide>
      </carousel-3d>
    </div>
    <!-- 团队简介 -->
    <div class="team_intro">
      <div class="intro_top">
        <span>团队简介/</span>
        <em>TEAM INTRODUCTION</em>
      </div>
      <div class="intro_mainText" >
        <img src="../assets/img/team(1).jpg" height="425" width="590" style="float: left;">
        <br>
        <p style="font-family:FangSong   ;margin-top: -5px;font-size: 17px;line-height: 24px;margin-bottom: -10px;">
          团队名称：月桐路科技创新团队</p>
        <p style="font-family:FangSong   ;font-size: 17px;line-height: 24px;margin-bottom: -10px;"> 团队口号：探索科技应用的无限可能</p>
        <p style="font-family:FangSong   ;font-size: 17px;line-height: 24px;margin-bottom: -10px;"> 团队宗旨：致力于通过科技创新，培养学生的探索精神和实践能力，推动科学技术在日常生活中的应用，解决实际问题。</p>
        <p style="font-family:FangSong   ;font-size: 17px;line-height: 24px;margin-bottom: -10px;"> 团队组成：团队成员由来自不同专业的学生组成，包括物联网工程、电子信息工程、信息工程和电子科学与技术等专业。每位成员都带来了独特的视角和专业知识，使团队在跨学科合作中能够产生创新的火花。</p>
        <p style="font-family:FangSong   ;font-size: 17px;line-height: 24px;margin-bottom: -10px;"> 团队成绩：近三年学科竞赛获奖累积约150项，获奖人次超过400人次，团队成绩多次被媒体报道，其中包括两次学习强国报道。</p>
        <p style="font-family:FangSong ; writing-mode: horizontal-tb;font-size: 17px;line-height: 24px;">今年，在团队负责人吴有龙副教授及其他指导老师的带领下，学生们充分利用课余时间，牺牲节假日，经过四个月的精心准备，斩获了20项国家级奖项和29项省级奖项。
          团队的目标不仅是提升学生的科技创新能力，更致力于激发学生的求真、明理和探索精神，为推动“万物感知、万物互联、万物智能”以及智慧城市建设贡献力量。月桐路团队的成员日常在学校月桐路的实验室中集中进行学习与项目研发，深耕科技创新，力求为未来的科研事业打下坚实基础。
        </p>
      </div>
    </div>

    <!-- 导师介绍 -->
    <div class="supervisor_intro ">
      <div class="team_intro">
        <div class="intro_top">
          <span>导师简介/</span>
          <em>TUTOR PROFILE</em>
        </div>
        <div class="intro_mainText">
          <img src="../assets/img/member/吴有龙.jpg" height="300" width="225px" style="float: left;">
          <br>
          <br>
          <span style="font-size: large; color:#1f2c5c;">吴有龙</span>
          <p class="wu" style=" writing-mode: horizontal-tb;font-family:STKaiti">
            吴有龙，1987年10月，博士（后），副教授。研究方向为惯性导航系统、GNSS/INS数据信息融合、随机理论及其在导航中的应用。攻博期间，由国家留学基金委资助于2011年9月至2013年9月在澳大利亚新南威尔士大学卫星导航实验室进行联合培养；2020获批国家留学基金委公派访问学者。学校优秀青年骨干教师。参与国家自然科学基金、国防基础预研等多项科研项目。主持江苏省现代教育技术研究教改课题2项，主持教育部产学合作协同育人项目2项；科研方面主持和参与国家自然基金等纵向课题4项，横向项目10余项，发表SCI/EI/CSCD等论文30余篇，授权发明专利7项，授权软件著作权7项。指导大学生省级大学生创新创业项目5项，指导学生参加中国大学生计算机设计大赛等学科竞赛获奖50余项，指导学生发表论文10余篇，指导学生完成软件著作权申请并授权10余项。          </p>
        </div>
        <hr color="#1f2c5c"/>
        <div class="intro_mainText">
          <img src="../assets/img/叶晴.jpg" height="300" width="225px"  style="float: left;">
          <br>
          <br>
          <br>
          <span style="font-size: large; color:#1f2c5c;">叶晴</span>
          <p class="ye" style=" writing-mode: horizontal-tb;font-family:STKaiti">
            叶晴，1989年4月，硕士研究生，讲师。研究方向为设计艺术学、思想政治教育。先后主持江苏省哲学社会科学思政专项课题1项，校级学生工作及党史教育课题3项。发表论文数篇，成立金陵科技学院首批辅导员“源创”创新创业工作室。参与编写“十三五”江苏省高等学校重点教材《服装立体剪裁》、参与编写《我的大学——大学新生第一课》。组织学生参加社会实践及团学活动，获江苏省“三下乡”暑期社会实践先进工作者，多次获得“金科好青年”、暑期社会实践“优秀指导教师”、“师德先进个人”及校园文化艺术节“优秀指导教师”等荣誉。指导大学生省级大学生创新创业项目2项，积极指导学生参与科技竞赛及专利软著授权。
          </p>
        </div>

      </div>
    </div>

    <!-- - 比赛官网 -->
    <div  class="supervisor_intro race" id="race" >
      <div class="team_intro ">
        <div class="intro_top">
          <span>比赛官网/</span>
          <em>Competition official website</em>
        </div>

        <div class="intro_mainText contest" >
          <!-- <img src="" height="300" style="float: left;"> -->
          <br>
          <div class="left"  id="matchLeft">
            <div class="match">
              <a href="http://jsjds.blcu.edu.cn/" target="_blank">
                <span style="font-size: large;">中国大学生计算机设计大赛</span>
              </a>
            </div>
            <div class="match">
              <a href="https://cie-eec.cn/" target="_blank">
                <span style="font-size: large;">“博创杯”全国大学生嵌入式人工智能设计大赛</span>
              </a>
            </div>
            <div class="match">
              <a href="https://www.ciiot.com/login" target="_blank">
                <span style="font-size: large; ">全国大学生物联网技术与应用“三创”大赛</span>
              </a>
            </div>
            <div class="match">
              <a href="https://www.robocom.com.cn/match.html?matchType=0" target="_blank">
                <span style="font-size: large;">RoboCom机器人开发者大赛</span>
              </a>
            </div>
            <div class="match">
              <a href="https://cy.ncss.cn/" target="_blank">
                <span style="font-size: large;">中国国际”互联网+“大学生创新创业大赛</span>
              </a>
            </div>
            <div class="match">
              <a href="http://net.c4best.cn/" target="_blank">
                <span style="font-size: large;">2023年网络技术挑战赛</span>
              </a>
            </div>
          </div>
          <div class="right" id="matchRight">
            <div class="match">
              <a href="https://www.beidoucup.com/web/index" target="_blank">
                <span style="font-size: large; ">“北斗杯”全国青少年空天科技体验与创新大赛</span>
              </a>
            </div>
            <div class="match">
              <a href="http://g-ican.com/home/index" target="_blank">
                <span style="font-size: large;">iCAN大赛未来农业机器人挑战赛</span>
              </a>
            </div>
            <div class="match">
              <a href="https://smt.whu.edu.cn/index.htm" target="_blank">
                <span style="font-size: large; ">全国大学生测绘学科创新创业智能大赛</span>
              </a>
            </div>
            <div class="match">
              <a href="http://www.socchina.net/" target="_blank">
                <span style="font-size: large; ">全国大学生嵌入式芯片与系统设计竞赛</span>
              </a>
            </div>
            <div class="match">
              <a href="https://dasai.lanqiao.cn/" target="_blank">
                <span style="font-size: large; ">蓝桥杯大赛</span>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--    <div class="supervisor_intro">-->
    <!--      <div class="team_intro">-->
    <!--        <div class="intro_top">-->
    <!--          <span>公告栏/</span>-->
    <!--          <em>TUTOR PROFILE</em>-->
    <!--        </div>-->
    <!--        <div class="intro_mainText">-->
    <!--          -->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- 新闻动态 -->
    <div class="news_trends" >
      <div class="team_intro" >
        <div class="intro_top">
          <span>新闻动态/</span>
          <em>NEWS TRENDS</em>
        </div>

        <div class="content" id="newsBox">
          <div class="boxleft" id="newsLeft">
            <h3>媒体报道-News</h3>
            <a href="https://jiangsu.eol.cn/jsgd/202408/t20240826_2629755.shtml" target="_blank">
              <div class="news1" >
                <br>
                <br>
                <br>
                <br>
                <p style="color:white;">&nbsp;&nbsp;优秀！四支队伍全部获奖，为金陵科技学院月桐路团队点赞！</p>
              </div>
            </a>

            <div class="news2">
              <a href=https://jiangsu.eol.cn/jsgd/202301/t20230104_2269332.shtml target="_blank">
                <div class="news2_1">
                  <img src="../assets/img/win3.jpg" height="140" width="220">
                  <p>成立不到两年，金陵科技学院这支科创团队“成绩单”亮眼！</p>
                </div>
              </a>
              <a href= "https://article.xuexi.cn/articles/index.html?art_id=2663280147123800380&cdn=https%3A%2F%2Fregion-jiangsu-resource&item_id=2663280147123800380&study_style_id=feeds_opaque&t=1673324197492&showmenu=false&ref_read_id=ffcb941c-3946-47eb-b890-ba4c1e101079_1673346749443&pid=&ptype=-1&source=share&share_to=wx_single" target="_blank">
                <div class="news2_2">
                  <img src="../assets/img/new4.jpg" height="140" width="220">
                  <p>金陵科技学院：这支科创团队成绩单亮眼！</p>
                </div>
              </a>
              <a href= "https://jnews.xhby.net/v3/waparticles/1312/0/6PvMWyri16xuQqwG/1" target="_blank">
                <div class="news2_3">
                  <img src="../assets/img/win2.jpg" height="140" width="220">
                  <p>金陵科技学院学生在全国大学生测绘学科创新创业智能大赛中创佳绩</p>
                </div>
              </a>
            </div>
          </div>

          <div class="boxright" id="newsRight">
            <h3>物联网嵌入式-NB-IOT</h3>
            <ul>
              <li><a href="https://www.ccidcom.com/baogao/20171114/97uThkgxjAbPjBJyL15bfa3bjys4o.html" target="_blank">我国低功耗广域网络（LPWAN）发展及展望</a></li>
              <li><a href="https://developer.aliyun.com/article/1362778" target="_blank">探秘嵌入式5G技术的新时代</a></li>
              <li><a href="https://news.cctv.cn/2024/11/04/ARTI59T5d3rTrWXEHwq2rgmh241104.shtml" target="_blank">中国走在了世界第一！今年我国物联网连接数有望突破30亿</a></li>
              <li><a href="https://developer.baidu.com/article/detail.html?id=441359" target="_blank">边缘计算与嵌入式系统</a></li>
              <li><a href="https://www.gov.cn/zhengce/zhengceku/202408/P020240827517418983244.pdf" target="_blank">物联网标准体系建设指南</a></li>
              <li><a href="https://www.gov.cn/xinwen/2023-01/30/content_5739121.htm" target="_blank">我国移动物联网连接数占全球70%</a></li>
            </ul>
            <h3>人工智能-Artificial intelligence</h3>
            <ul>
              <li><a href="https://www.163.com/dy/article/IH93UT100538A53W.html" target="_blank">2024杭州人工智能展会（世亚智博会）一场人工智能领域的视觉盛宴</a></li>
              <li><a href="https://hub.baai.ac.cn/view/34210" target="_blank">生成式AI的发展现状</a></li>
              <li><a href="https://www.sohu.com/a/814702199_121924584" target="_blank">量子计算与人工智能的碰撞</a></li>
              <li><a href="https://www.tmtpost.com/7276579.html" target="_blank">算力两极分化，边缘计算AI升级</a></li>
            </ul>

          </div>
        </div>
      </div>

      <div class="supervisor_intro">
        <div class="team_intro">
          <div class="intro_top">
            <span>政策报道-Policy coverage</span>
          </div>
          <div class="content_1" id="newsBox_1">
            <div class="boxleft_1" id="newsLeft_1">
              <h3>政策新闻-News</h3>
              <a href="http://www.qstheory.cn/zhuanqu/2021-11/28/c_1128109948.htm" target="_blank">
                <div class="news_xi" >
                  <br>
                </div>
              </a>
            </div>
            <div class="boxright_1" id="newsRight_1">
              <h3>科技创新-Scientific and technological innovation</h3>
              <ul>
                <li><a href="https://finance.sina.com.cn/jjxw/2023-11-11/doc-imzufsqu9461808.shtml" target="_blank">OpenAI开发者大会背后：又一批创业公司要遭“灭顶之灾”</a></li>
                <li><a href="http://finance.people.com.cn/n1/2023/1020/c1004-40099582.html" target="_blank">科技部：以高质量科普服务科技强国建设</a></li>
                <li><a href="http://finance.people.com.cn/n1/2023/1113/c1004-40116978.html" target="_blank">“空天科创营”开启：拓展“北斗杯”大赛成果 激励青少年创新</a></li>
                <li><a href="http://finance.people.com.cn/n1/2023/1113/c1004-40116483.html" target="_blank">数字技术深度融入生活 数字公共服务普惠便捷（前沿动态）</a></li>
                <li><a href="http://hn.people.com.cn/n2/2023/1114/c356884-40640312.html" target="_blank">2023数字科技生态大会：构建数字科技新生态 启航数字科技新未来</a></li>
                <li><a href="http://nm.people.com.cn/n2/2023/1028/c196678-40619859.html" target="_blank">校园科技馆开馆</a></li>
                <li><a href="https://www.163.com/dy/article/ILHHP98M0552ADWT.html" target="_blank">加快推动以科技创新为核心的全面创新</a></li>

              </ul>
            </div>

          </div>
        </div>
      </div>


    </div>

    <!-- 底部区域 -->
    <div class="bottom" id="bottomBox">
      <div class="footer">
        <div class="boxf">
          <div>
            <h3>团队介绍</h3>
            <p>团队简介</p>
            <p>导师简介</p>
          </div>
          <div>
            <h3>团队风采</h3>
            <p>团队代表</p>
            <p>成员风采</p>
          </div>
          <img src="../assets/img/1.1.png" alt="Logo" class="logof">
          <div>
            <h3>科研活动</h3>
            <p>荣誉证书</p>
            <p>作品展示</p>
          </div>
          <div>
            <h3>关于月桐路团队</h3>
            <p>服务：757016469</p>
            <p>咨询：1072141599@qq.com</p>
          </div>
        </div>
      </div>
      <div class="footerr">
        <p>Copyright©2023-2023&nbsp; 月桐路科技创新团队&nbsp; All Right Reserved <a href="https://beian.miit.gov.cn/" target="_blank">&nbsp; 苏ICP备2023052797号-1 </a>&nbsp;
          <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank"><img style="width: 17px;height: 17px;margin-bottom: -4px" src="../assets/img/police.png"> 苏公网安备32011502011945号</a>
        </p>
      </div>

    </div>

  </div>

</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'

export default {
  components: {
    Carousel3d,
    Slide
  },
  data() {
    return {
      turnWhite: false,
      slides: [
        {

          title: 'parent',
          slide: 23424234234234,
          src: require("@/assets/img/school3.jpg"),
        },
        {
          title: 'parent',
          slide: 23424234234234,
          src: require("@/assets/img/library.jpg"),
        },
        {
          title: 'parent',
          slide: 23424234234234,
          src: require("@/assets/img/school4.jpg"),
        },
      ]
    }
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
    this.handleScroll()
  },
  methods: {
    handleScroll() {
      const triggerBottom = window.innerHeight / 6 * 5
      const matchBox = document.getElementById('race')
      const matchLeft = document.getElementById('matchLeft')
      const matchRight = document.getElementById('matchRight')

      const newsBox = document.getElementById('newsBox')
      const elementLeft = document.getElementById('newsLeft')
      const elementRight = document.getElementById('newsRight')

      const newsBox_1 = document.getElementById('newsBox_1')
      const elementLeft_1 = document.getElementById('newsLeft_1')
      const elementRight_1 = document.getElementById('newsRight_1')

      const bottomBox = document.getElementById('bottomBox')

      //左侧比赛
      if (matchLeft) {
        const boxTop = matchBox.getBoundingClientRect().top
        if(boxTop < triggerBottom) {
          matchLeft.classList.add('show')
        } else {
          matchLeft.classList.remove('show')
        }
      } else {}
      //右侧比赛
      if (matchRight) {
        const boxTop = matchBox.getBoundingClientRect().top
        if(boxTop < triggerBottom) {
          matchRight.classList.add('show')
        } else {
          matchRight.classList.remove('show')
        }
      } else {}


      //左侧新闻
      if (elementLeft) {
        const boxTop = newsBox.getBoundingClientRect().top
        if(boxTop < triggerBottom) {
          elementLeft.classList.add('show')
        } else {
          elementLeft.classList.remove('show')
        }
      } else {}
      //右侧新闻
      if (elementRight) {
        const boxTop = newsBox.getBoundingClientRect().top
        if(boxTop < triggerBottom) {
          elementRight.classList.add('show')
        } else {
          elementRight.classList.remove('show')
        }
      } else {}

      //左侧报道
      if (elementLeft_1) {
        const boxTop_1 = newsBox_1.getBoundingClientRect().top
        if(boxTop_1 < triggerBottom) {
          elementLeft_1.classList.add('show')
        } else {
          elementLeft_1.classList.remove('show')
        }
      } else {}
      //右侧报道
      if (elementRight_1) {
        const boxTop_1 = newsBox_1.getBoundingClientRect().top
        if(boxTop_1 < triggerBottom) {
          elementRight_1.classList.add('show')
        } else {
          elementRight_1.classList.remove('show')
        }
      } else {}


      //底部栏
      if (bottomBox) {
        const bottomTop = bottomBox.getBoundingClientRect().top
        if(bottomTop < triggerBottom) {
          bottomBox.classList.add('show')
        } else {
          bottomBox.classList.remove('show')
        }
      } else {}

    },
  },


};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment : fixed ;
  overflow-x: hidden;
  overflow-y: hidden;
}
.logof{
  width: 260px;
  height: 110px;
  padding-left: 40px;
}

ul {
  list-style: none;
}
.home-container {
  height: 100%;
}

.el-main {
  background-color: white;
}
.match{
  width: 500px;
  height: 30px;
  font-size: 26px;
  line-height: 30px;
  margin-left: 20px;
  margin-bottom: 20px;
  float: left;
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
  span{

    margin-right: 40px;
    text-align: center;
  }
}

.match:hover {
  transform: scale(1.2);
  transition: 0.5s;
  color:	#0e3779;

}
.el-footer {
  background-color: aqua;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.team_intro {
  width: 1200px;
  height: 350px;
  margin: 0 auto;
  content: "";
  display: table;
  clear: both;
}

.intro_top {
  margin-top: 30px;
  line-height: 38px;
  //background-color: rgba(221, 237, 239, 0.5);
  background-color: rgba(241, 242, 245, 0.9);
  border-block: solid;
  writing-mode: horizontal-tb;

}

.intro_top span {
  margin-left: 10px;
  font-size: 28px;
  color: #1f2c5c;
}

.intro_top em {
  font-size: 16px;
  color: #2d2d2d;
}

.intro_mainText {
  height: 300px;

  color:#414141;
  font-size: 16px;
  //background-color: rgba(242, 234, 234, 0.7);
  background-color: white;
}

.intro_mainText img {
  margin-right: 20px;
}

.intro_mainText a {
  margin-left: 75px;
}

.supervisor_intro {
  margin: 15px 0;
}

.race {
  height: 350px;
}

</style>


<style scoped>
.news_trends{
  margin-bottom: 70px;
}

.boximg{
  box-shadow: rgb(44, 54, 63) 0px 0px 30px 5px;
}
.content,.content_1{
  width:100%;
  height:max-content;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.7);
  padding-top: 20px;
}
.boxleft,.boxleft_1{
  width: 60%;
  height: max-content;
  color: #1f2c5c;
  padding-left: 20px;
  /* background-color: #d80460; */
  /* transform: translateX(-50%);
  transition: transform 0.4s ease; */
}

/* .show {
  transform: translateX(0);
  background-color: aquamarine;
} */

.boxleft h3,.boxleft_1 h3 {
  width: 100%;
  line-height: 36px;
  text-align: center;
  background-color: rgb(224, 226, 226);
}

.news1{
  width:100%;
  height:350px;
  padding-top: 230px;
  background: url(../assets/img/main2.jpg);
  background-size: cover;
  color: #fffdfd;
  margin-bottom: 20px;
}
.news_xi{
  width:100%;
  height:350px;
  padding-top: 230px;
  background: url(../assets/img/习近平.jpg);
  background-size: cover;
  color: #fffdfd;
  margin-bottom: 20px;
}
.news1:hover {
  color:rgb(0, 0, 0);
  transform: scale(1.05);
  transition: 0.5s;
}

.news2{
  display: flex;
  color:#414141;
}

.news img {
  border-radius: 4px;
}
.news2 img:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.news2_1:hover,.news2_2:hover,.news2_3:hover{
  color:	#0e3779;
  text-decoration: underline;
  transform: scale(1.1);
  transition: 0.5s;
}
.news2_1{
  width:220px;
  height:120px;
  margin-top: 15px;

}
.news2_2{
  width:220px;
  height:120px;
  margin-top: 15px;
  margin-left: 23px;

}
.news2_3{
  width:220px;
  height:120px;
  margin-top: 15px;
  margin-left: 23px;
}

.boxright,.boxright_1{
  box-sizing: border-box;
  margin-left: 20px;
  border-left: #b9b9b9 1px solid;
  width: 40%;
  height: max-content;
  color: #1f2c5c;
  /* background-color: aquamarine; */
  /* transform: translateX(50%);
  transition: transform 0.4s ease; */
}

.boxright ul,.boxright_1 ul {

  padding-left: 20px;
}

.boxright h3 ,.boxright_1 h3{
  line-height: 36px;
  text-align: center;
  background-color: rgb(224, 226, 226);
}

.boxright p ,.boxright_1 p{
  border-bottom: 2px solid #1f2c5c;
}

.boxright li ,.boxright_1 li{
  margin: 15px 20px 15px 0;
  font-size: 16px;
  color:#414141;
  border-bottom: 1px rgb(176, 167, 167) solid;
  padding-bottom: 15px;
}
.boxright li:hover,.boxright_1 li:hover{
  color:	#0e3779;
  text-decoration: underline;
  transform: scale(1.1);
  transition: 0.5s;
}
.boxright li span,.boxright_1 li span{
  color: #d80460;
  font-weight: bolder;
}



/*   底部 */
.bottomTop{

  height: 100px;
}
.footer{
  padding: 20px 10% 10px;
  background-color:black;
  color: #fff;
  height: 130px;
}
.boxf {
  height:80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size:small;
  line-height: 1.875;
}
.footerr{
  padding-top: 1px;
  width: 100%;
  height: 40px;
  background-color:  #2c2c2c;
  font-size:10px;
  color: #fff;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
}

.wu {
  font-size: 18px;
  line-height: 24px;
  margin-right: 5px;
}

.ye {
  font-size: 18px;
  line-height: 24px;
  margin-right: 7px;
}

.contest {
  height: 232px;
  display: flex;
  flex-wrap: nowrap;
}

.left, .right {
  padding-top: 15px;
}

/* 滑动效果 */
.bottom {
  transform: translate3d(0,-100px,0);
  transition: transform 0.5s ease;
  opacity: 0;
  transition-property: opacity,transform;
}

.boxleft, .boxleft_1, .left {
  transform: translate3d(-1170px,150px,0);
  transition: transform 0.4s ease;
  opacity: 0;
  transition-property: opacity,transform;
}

.boxright, .boxright_1, .right {
  transform: translate3d(2000px,150px,0);
  transition: transform 0.4s ease;
  opacity: 0;
  transition-property: opacity,transform;
}

.show {
  opacity: 1;
  transform: translateZ(0);
}



</style>