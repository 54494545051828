<template>
  <div>
    <el-dialog width="60%" :title="diaTitle" :visible.sync="dialogVisb" @close="handleClose" :close-on-click-modal="false">
      <el-form ref="awardData" :model="data" label-width="80px">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="比赛年份" :rules="competitionYearRules" prop="competitionYear">
              <el-select
                  v-model="data.competitionYear"
                  :disabled="disableOrNot"
                  @change="changeName"
                  style="width: 100%">
                <el-option
                    v-for="item in competitionYears"
                    :key="item.value"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="比赛名称" prop="competitionId" :rules="competitionIdRules">
              <el-select v-model="data.competitionId" :disabled="disableOrNot" style="width: 100%">
                <el-option
                    v-for="item in competitions"
                    :key="item.id"
                    :label="item.competitionName"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="项目名称" prop="projectId" :rules="projectIdRules">
              <project-tree
                  v-model="data.projectId"
                  :disableOrNot="disableOrNot"
                  :project-data="projectDataAll"
                  :existProjectId="existProjectId"
                  :project-data-props="{value: 'value',label: 'label',children: 'children'}"
                  placeholder="请选择项目名称"
                  size="mini"
                  @update:projectId="handleUpdate"
                  filterable style="width: 100%">
              </project-tree>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学生姓名" prop="studentId">
              <student-tree
                  style="width: 100%"
                  :disableOrNot="disableOrNot"
                  :existStudentIds="existStudentIds"
                  :studentData="studentDataAll"
                  :studentDataProps="{value: 'value',label: 'label',children: 'children'}"
                  @update:userIds="handleUpdateStudent"
                  placeholder="请选择学生姓名"
                  class="position-select"
                  size="mini"
                  filterable>
              </student-tree>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="指导教师" prop="teacherId">
              <teacher-tree
                  style="width: 100%"
                  :disableOrNot="disableOrNot"
                  :existTeacherIds="existTeacherIds"
                  :teacherData="teacherDataAll"
                  :teacherDataProps="{value: 'value',label: 'label',children: 'children'}"
                  @update:userIds="handleUpdateTeacher"
                  placeholder="请选择老师姓名"
                  class="position-select"
                  size="mini"
                  filterable>
              </teacher-tree>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国/省赛" prop="level" :rules="levelRules">
              <el-select v-model="data.level" :disabled="disableOrNot" style="width: 100%">
                <el-option
                    v-for="item in levels"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="获奖等级" prop="grade" :rules="gradeRules">
              <el-select v-model="data.grade" :disabled="disableOrNot" style="width: 100%">
                <el-option
                    v-for="item in grades"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证书上传">
              <el-upload v-if="type === 'edit' || type === 'add'"
                  :disabled="disableOrNot"
                  accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                  action
                  :on-remove="handleRemove"
                  :before-upload="beforeUpload"
                  :file-list="data.fileList">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <span
                  v-if="type === 'detail'"
              >{{data.competitionName + data.studentName + ".jpg"}}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item v-if="disableOrNot" label="修改人">
              <el-input :disabled="disableOrNot" v-model="data.updatedBy"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="disableOrNot" label="修改时间">
              <el-input :disabled="disableOrNot" v-model="data.updationDate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: right">
        <el-button @click="handleClose()">取消</el-button>
        <el-button v-if="!disableOrNot" type="primary" @click="save()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import awardService from '@/api/awards'
import competitionService from "@/api/competition";
import projectService from "@/api/project";
import projectTree from "@/components/tree/ProjectTree";
import StudentTree from "@/components/tree/StudentTree";
import manageService from "@/api/manage";
import TeacherTree from "@/components/tree/TeacherTree";
import dictionary from "@/api/dictionary";

export default {
  name: 'AddOrModifyDialog',
  components: {projectTree, StudentTree, TeacherTree},
  props: {
    type: String,
    awardId: String,
    dialogFormVisible: Boolean,
  },
  data() {
    return {
      disableOrNot: false,
      diaTitle: '',
      data: {},
      existProjectId: [],
      competitionYears: [],
      levels: [
        {
          value: '国赛',
          code: 'national_race'
        },
        {
          value: '省赛',
          code: 'provincial_race'
        }
      ],
      grades: [
        {
          value: '特等奖',
          code: 'special_prize'
        },
        {
          value: '一等奖',
          code: 'first_prize'
        },
        {
          value: '二等奖',
          code: 'second_prize'
        },
        {
          value: '三等奖',
          code: 'third_prize'
        }
      ],
      competitions: [],
      projects: [],
      studentIds: [],
      teacherIds: [],
      projectDataAll: [],
      existStudentIds: [],
      studentDataAll: [],
      existTeacherIds: [],
      teacherDataAll: [],
    }
  },
  watch: {
    type: {
      handler(newval, oldval) {
        if (newval === 'add') {
          this.diaTitle = '新增'
          this.disableOrNot = false
        }
        if (newval === 'edit') {
          this.diaTitle = '编辑'
          this.disableOrNot = false
        }
        if (newval === 'detail') {
          this.diaTitle = '详情'
          this.disableOrNot = true
        }
      },
      immediate: true,
    },
  },
  computed: {
    dialogVisb: {
      get() {
        return this.dialogFormVisible
      },
      set(v) {
        this.$emit('update:dialogFormVisible', v)
      }
    },
    competitionYearRules() {
      return [{
        required: true,
        message: '比赛年份不能为空',
        trigger: 'blur'
      }]
    },
    competitionIdRules() {
      return [{
        required: true,
        message: '比赛名称不能为空',
        trigger: 'blur'
      }]
    },
    projectIdRules() {
      return [{
        required: true,
        message: '项目名称不能为空',
        trigger: 'blur'
      }]
    },
    levelRules() {
      return [{
        required: true,
        message: '国/省赛不能为空',
        trigger: 'blur'
      }]
    },
    gradeRules() {
      return [{
        required: true,
        message: '奖项等级不能为空',
        trigger: 'blur'
      }]
    },
  },
  mounted() {
    this.getDic();
    this.init();
  },

  methods: {
    // 初始化
    init(){
      this.searchAllYears();
      this.searchAllProjects();
      this.$nextTick(() => {
        this.searchAllUsers().then(res => {
          if (this.awardId && this.awardId !== ''){
            awardService.getById(this.awardId).then((res) => {
              if (res.state) {
                this.data = res.data;
                this.existProjectId = [res.data.projectId];
                this.data.competitionYear = '' + this.data.competitionYear;
                this.existStudentIds = res.data.userInfos.filter(item => item.roleId === "1003").map(item => item.userId);
                this.existTeacherIds = res.data.userInfos.filter(item => item.roleId === "1002").map(item => item.userId);
                competitionService.searchByYear(this.data.competitionYear).then((res) => {
                  if (res.state) {
                    this.competitions = res.data;
                  }
                })
              }
            })
          }
        })
      });
    },

    // 字典初始化
    getDic(){
      // 获奖等级
      dictionary.getDicByCode("award_level").then(res => {
        if (res.state){
          this.grades = res.data;
        }
      })
      // 赛事等级
      dictionary.getDicByCode("competition_level").then(res => {
        if (res.state){
          this.levels= res.data;
        }
      })
    },

    // 查询所有比赛年份信息
    searchAllYears() {
      competitionService.searchAllYears().then(res => {
        this.competitionYears = res.data;
      })
    },

    // 查询所有项目信息
    searchAllProjects() {
      projectService.searchProjectName().then(res => {
        this.projectDataAll = this.projectListToTree(res.data);
      })
    },

    // 查询所有人员数据
    searchAllUsers() {
      return new Promise((resolve, reject) => {
        manageService.searchAllUsers().then((res) => {
          this.studentDataAll = this.studentListToTree(res.data);
          this.teacherDataAll = this.teacherListToTree(res.data);
          resolve();
        })
      })

    },

    // 根据比赛年份查询相应比赛
    changeName() {
      competitionService.searchByYear(this.data.competitionYear).then((res) => {
        if (res.state) {
          this.competitions = res.data;
        }
      })
    },

    // 保存
    save() {
      this.data.userIds = this.studentIds.concat(this.teacherIds);
      this.$refs['awardData'].validate((valid) => {
        if (valid) {
          if (this.type === 'add') {
            awardService.insertOrUpdate(this.data).then((res) => {
              if (res.state) {
                this.$message.success('新增成功')
                this.handleClose()
                this.$emit('refresh')
              }
            })
          }
          if (this.type === 'edit') {
            if (!/[A-Za-z]/.test(this.data.grade)) {
              this.data.grade = this.grades.find(item => item.value === this.data.grade);
            }
            if (!/[A-Za-z]/.test(this.data.level)) {
              this.data.level = this.levels.find(item => item.value === this.data.level);
            }
            awardService.insertOrUpdate(this.data).then((res) => {
              console.log(this.data);
              if (res.state) {
                this.$message.success('修改成功')
                this.handleClose()
                this.$emit('refresh')
              }
            })
          }
        } else {
          return false;
        }
      })
    },

    // 关闭对话框
    handleClose() {
      this.existStudentIds = []
      this.existTeacherIds = []
      this.data = {}
      this.dialogVisb = false
    },

    // 文件上传
    handleRemove(file, fileList) {
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return false;
      }

      let form = new FormData()
      form.append("file", file);
      awardService.uploadFile(form).then(res => {
        this.data.imageUrl = res.data;
      })
      return false;
    },

    handleUpdate(newId) {
      this.data.projectId = newId;
    },
    handleUpdateStudent(newId) {
      this.studentIds = newId;
    },
    handleUpdateTeacher(newId) {
      this.teacherIds = newId;
    },

    // 项目树形结构数据
    projectListToTree(data) {
      let projectData = [];
      let yearMap = {}; // 用于快速查找年份节点

      for (const project of data) {

        // 处理年份节点
        if (!yearMap[project.projectYear.toString()]) {
          const yearNode = this.createNode(this.createRandomId(), project.projectYear.toString());
          projectData.push(yearNode);
          yearMap[project.projectYear.toString()] = yearNode;
        }

        // 添加项目信息节点
        const projectNode = yearMap[project.projectYear.toString()];
        projectNode.children.push(this.createNode(project.id, project.projectName, true));
      }

      return projectData;
    },

    // 学生树形结构数据
    studentListToTree(data) {

      let studentData = [];
      let professionMap = {}; // 用于快速查找专业节点
      let entryYearMap = {}; // 用于快速查找入学年份节点

      for (const student of data) {
        if (!student.profession || !student.entryYear || !student.id || !student.userName || student.roleId !== '1003') {
          continue; // 跳过缺失必要属性的学生对象
        }

        // 处理专业节点
        if (!professionMap[student.profession]) {
          const professionNode = this.createNode(this.createRandomId(), student.profession);
          studentData.push(professionNode);
          professionMap[student.profession] = professionNode;
          entryYearMap[student.profession] = {}; // 初始化当前专业的入学年份映射
        }

        // 处理入学年份节点
        const professionNode = professionMap[student.profession];
        if (!entryYearMap[student.profession][student.entryYear]) {
          const entryYearNode = this.createNode(this.createRandomId(), student.entryYear.toString());
          professionNode.children.push(entryYearNode);
          entryYearMap[student.profession][student.entryYear.toString()] = entryYearNode;
        }

        // 添加学生信息节点
        const entryYearNode = entryYearMap[student.profession][student.entryYear];
        entryYearNode.children.push(this.createNode(student.id, student.userName, true));
      }

      return studentData;
    },

    // 教师树形结构数据
    teacherListToTree(data) {
      let teacherData = [];
      let academyMap = {}; // 用于快速查找专业节点

      for (const teacher of data) {
        if (!teacher.id || !teacher.userName || !teacher.academy || teacher.roleId !== '1002') {
          continue;
        }

        if (!academyMap[teacher.academy]) {
          const academyNode = this.createNode(this.createRandomId(), teacher.academy);
          teacherData.push(academyNode);
          academyMap[teacher.academy] = academyNode;
        }

        const teacherNode = academyMap[teacher.academy];
        teacherNode.children.push(this.createNode(teacher.id, teacher.userName, true));
      }
      return teacherData;
    },

    // 创建树节点
    createNode(id, label, isLeaf = false) {
      return {
        id,
        label,
        children: [],
        ...(isLeaf && {isLeaf: true}), // 只有叶子节点才添加isLeaf属性
      };
    },

    // 生成一个随机的字符串作为 ID
    createRandomId() {
      return Math.random().toString(36).substr(2, 9);
    }
  },
}
</script>
<style src="../../../../../node_modules/@wangeditor/editor/dist/css/style.css"></style>
<style scoped>

</style>
